const BetCircle = ({win, lost, children, onClick}) => {

    const fillColor = () => {
        if ( win ) {
          return '#4caf50';
        }
        if ( lost ) {
          return '#b71c1c';
        }
        return '#CCC';
    }
    return (
        <svg viewBox="0 0 240 207" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g className="circle-fill" transform="translate(-281.000000, -31.000000)" fill={fillColor()} fillRule="nonzero">
                <g transform="translate(231.000000, -19.000000)">
                <path
                    d="M170,50 C236.27417,50 290,103.72583 290,170 C290,236.27417 236.27417,290 170,290 C167.238576,290 165,287.761424 165,285 C165,282.238576 167.238576,280 170,280 C230.751322,280 280,230.751322 280,170 C280,109.248678 230.751322,60 170,60 C109.248678,60 60,109.248678 60,170 C60,172.761424 57.7614237,175 55,175 C52.2385763,175 50,172.761424 50,170 C50,103.72583 103.72583,50 170,50 Z"
                    id="Oval"
                    transform="translate(170.000000, 170.000000) rotate(-45.000000) translate(-170.000000, -170.000000) "></path>
                </g>
            </g>
            </g>
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold"
                fontSize="40" fill={fillColor()} fontFamily="Ubuntu" className="text-fill">
                {children}
            </text>
        </svg>
    )
}

export default BetCircle;