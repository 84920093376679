import AdditionalBetBox from "../content/additionalBetBox/AdditionalBetBox";

const AdditionalBets = ({coefficient, frontStore, bet}) => {
    return (<>
        <div className="p2-bets-wrapper">
            {coefficient?.player2 && Object.keys(coefficient?.player2).map((betName, index) => {
                if (betName === 'win') {return ''}
                return <AdditionalBetBox coefficient={coefficient?.player2[betName]} betName={betName} bet={bet} frontStore={frontStore} player={"player2"} key={`p2-${index}-${betName}`}/>
            })}
        </div>
        <div className="p1-bets-wrapper">
            {coefficient?.player1 && Object.keys(coefficient?.player1).map((betName, index) => {
                if (betName === 'win') {return ''}
                return <AdditionalBetBox coefficient={coefficient?.player1[betName]} betName={betName} bet={bet} frontStore={frontStore} player={"player1"} key={`p1-${index}-${betName}`}/>
            })}
        </div>
    </>);
}

export default AdditionalBets;