import Card from "../card";

const RenderCard = ({cardNumber, i, player, frontStore}) => {
    const game = frontStore?.game;
    const cards = frontStore?.game?.cards;

    if (!cards) {
        return '';
    }
    let cardName = 'emptyCard';
    if (!!cards && !!cards[player] && cards[player][cardNumber]) {
        cardName = cards[player][cardNumber].value;
    }
    if (game.currentConf?.player === player && game.currentConf?.card-1 === (cardNumber) && cardName === 'emptyCard') {
        cardName = 'emptyActiveCard';
    }
    
    return (
        <Card key={player+i} cardName={cardName} />  
    )
}

export default RenderCard;