import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FrontSocketContext from "../../../store/FrontSocketContext";
import "./Chat.scss"
import { Button, Col, Row, Typography, message} from "antd";
import { useRef } from "react";
import {CHAT_DELAY} from "./../../../config/defaults";
import { useTranslation } from "react-i18next";
import MovableWindow from "../movableWindow/MovableWindow";
import { frontActions } from "../../../store/slice/frontSlice";
import {SendOutlined} from '@ant-design/icons';

const Chat = ({openChat, toggleLiveChat, closeCallBack = () => {}}) => {
    const chatStore = useSelector(store => store.chat);
    const frontStore = useSelector((store) => store.front);
    const [playerMessage, setMessage] = useState('');
    const [disableChat, setDisableChat] = useState(false);
    const socket = useContext(FrontSocketContext);
    const chatRef = useRef();
    const dealer = frontStore?.game?.table?.dealer;
    const { t } = useTranslation("translation");
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    let chatMessages = chatStore.playerMessages;
    
    const sendMessgae = () => {
        if (frontStore.blocked) {
            messageApi.open({
                type: 'error',
                content: 'Your messages are blocked temporary',
            });
            return;
        }
        if (playerMessage) {
            if (!disableChat) {
                socket.emit("playerMessage", {message: playerMessage});
            }
            setDisableChat(true);
            setTimeout(() => {
                setDisableChat(false);
            }, CHAT_DELAY*1000);
        }
        setMessage("")
    }

    const chatBoxConditionalClass = openChat ? '': 'hide-chat';
    useEffect(() => {
        console.log("CHato")
        if (chatRef.current && frontStore.chatOpened) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [frontStore.chatOpened]);

    if (!frontStore.chatOpened) {
        return '';
    }
    
    if (chatMessages) {
        chatMessages = [...chatMessages].reverse();
    }

    return (<MovableWindow title={t('chat')} closeCallBack={() =>  {
        dispatch(frontActions.updateChatOpened(false))
        closeCallBack()
        }}>
        <Row className="chat-container">
            <div className="chat-top-panel">
                <div  className="chat-left-title">
                    {t('dealer')}: <span className="special-text">{dealer?.nickName}</span>
                </div>
                <div className="clear-both"></div>
            </div>
            <Row className="chat-messages" ref={chatRef}>
                <Col>
                    {chatMessages && chatMessages.map((messageObj, index) => {
                        return (
                            <Row key={messageObj.message+index} className="message-blob-container">
                                <Col className="message-blob">
                                    <Typography.Text className='message'>
                                        {messageObj.playerUserName ? (<><span className="player-username">{messageObj.playerUserName} </span> <span> - {messageObj.message}</span></>): messageObj.message}
                                    
                                    </Typography.Text>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
            <Col span={24}>
                
                <Row className="chat-input-message">
                    <Col span={21}>
                        {contextHolder}
                        <input 
                            name="chat_message"
                            className="chat-input"
                            value={playerMessage}
                            disabled={disableChat}
                            placeholder={t('chat_input_placeholder')}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessgae(playerMessage)
                                }
                            }}
                        />
                    </Col>
                    <Col span={3}>
                        <Button onClick={sendMessgae} disabled={disableChat} className="send-message"> 
                            <SendOutlined />
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </MovableWindow>)
    return (<Row className={`player-chat-wrapper ${chatBoxConditionalClass}`}>
        <Row>
            <Col>
                <Typography.Text onClick={() => toggleLiveChat()} style={{color: "black"}}>X</Typography.Text>
                <Typography.Title level={2}>{dealer?.nickName}</Typography.Title>
            </Col>
        </Row>
        
    </Row>)
}

export default Chat;