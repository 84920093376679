import "./TableChip.scss";

const TableChip = ({amount}) => {
    const parseChipValue = (value) => {
        if ( !value ) return '';
        value = parseInt(value);
        let parsedText = '0';
        if ( value < 1000 ) {
          parsedText = value.toString();
        }
        if ( value >= 1000 ) {
          parsedText = (value / 1000).toFixed(2).toString() + 'K';
          parsedText = parsedText.replace("0K", "K")
        }
        if ( value >= 1000000 ) {
          parsedText = (value / 1000000).toFixed(2).toString() + 'M';
          parsedText = parsedText.replace("0M", "M")
        }
        if ( value >= 1000000000 ) {
          parsedText = (value / 1000000000).toFixed(2).toString() + 'B';
          parsedText = parsedText.replace("0B", "B")
        }
        if ( value >= 1000000000000 ) {
          parsedText = (value / 1000000000000).toFixed(2).toString() + 'T';
          parsedText = parsedText.replace("0T", "T")
        }
        if ( value >= 1000000000000000 ) {
          parsedText = (value / 1000000000000000).toFixed(2).toString() + 'Q';
          parsedText = parsedText.replace("0Q", "Q")
        }
        parsedText = parsedText.replace(/\.0K+$/, 'K');
     
        return parsedText;
    }

    const changeStyle = (amount) => {
        let value = parseInt(amount);
        let B = value % 256;
        let G = ((value - B) / 256) % 256;
        let R = (256 * (parseInt(value.toString().substring(0, 2)) / 100)).toFixed(0) - ((value - B) / 256 ** 2) - G / 256;
        return "rgb(" + Math.round(R) + "," + G + "," + B + ")";
    }
    if (!amount) {
      return '';
    }
    return (
        <div className="table-chip-conatiner chip-container bet-chip chip-animate-slide-to-bottom" >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 24 24" xmlSpace="preserve">
                <path fill={changeStyle(amount)} d="M14,17.1l-0.1-0.3l0,0c0.6-0.1,1.2-0.3,1.8-0.6l0.4,0.5c-0.6,0.3-1.3,0.5-2,0.6L14,17.1z M9.8,17.4l0.2-0.6
                    c0.6,0.1,1.3,0.2,2,0.2l0,0.6C11.2,17.6,10.5,17.6,9.8,17.4z M17.4,15.3c0.5-0.4,0.9-0.8,1.2-1.2l0.7,0.3c-0.4,0.5-0.8,0.9-1.4,1.3
                    L17.4,15.3z M6,15.7l0.6-0.4c0.5,0.4,1,0.7,1.6,0.9l-0.4,0.5C7.1,16.5,6.5,16.1,6,15.7z M19.4,12.7c0.2-0.5,0.3-1,0.3-1.5l0.8,0
                    c0,0.6-0.1,1.1-0.3,1.7L19.4,12.7z M3.8,12.8L3.8,12.8l0.8-0.2c0.2,0.5,0.4,1,0.8,1.4l-0.7,0.3C4.3,13.9,4,13.4,3.8,12.8z M19.4,9.7
                    c-0.2-0.5-0.4-1-0.7-1.4L19.3,8c0.4,0.5,0.6,1,0.8,1.6l0,0L19.4,9.7z M3.5,11.1c0-0.6,0.1-1.1,0.3-1.7l0.8,0.2
                    c-0.2,0.5-0.3,1-0.3,1.5L3.5,11.1z M15.9,6.1l0.4-0.5c0.6,0.3,1.2,0.6,1.7,1L17.4,7C17,6.7,16.4,6.4,15.9,6.1z M4.7,7.9
                    C5.1,7.4,5.5,7,6.1,6.6L6.6,7C6.1,7.3,5.7,7.8,5.4,8.2L4.7,7.9z M12,5.3l0-0.6c0.7,0,1.5,0.1,2.2,0.2L14,5.5
                    C13.4,5.4,12.7,5.3,12,5.3z M7.8,5.5c0.6-0.3,1.3-0.5,2-0.6c0,0,0,0,0,0l0,0.1L10,5.5C9.4,5.6,8.8,5.8,8.2,6.1L7.8,5.5z"/>
                <g>
                    <path fill="none" d="M10,5.5c-4.1,0.8-6.5,4-5.4,7.2c1.1,3.1,5.3,5,9.4,4.2c4.1-0.8,6.5-4,5.4-7.2C18.3,6.5,14.1,4.7,10,5.5z"/>
                        <path fill="none" d="M12,20.3c0.4,0,0.8,0,1.2-0.1C12.8,20.3,12.3,20.3,12,20.3C11.9,20.3,12,20.3,12,20.3z"/>
                        <path fill="#ffffff" d="M14.4,4.4l0.7-2c-2-0.4-4.1-0.5-6.2,0l0.7,2C11.3,4,12.9,4,14.4,4.4z"/>
                        <path fill="#ffffff" d="M20.9,9.4l2.6-0.5c-0.6-1.6-1.6-3-3.1-4.1l-1.9,1.5C19.6,7,20.5,8.1,20.9,9.4z"/>
                        <path fill="#ffffff" d="M5.5,6.2L3.6,4.7C2.1,5.8,1,7.2,0.4,8.8l2.6,0.6C3.5,8.1,4.3,7,5.5,6.2z"/>
                        <path fill="#ffffff" d="M3.1,13l-2.6,0.5c0,0,0,0,0,0v0h0c0,0,0,0,0,0v1.9c0.5,1.6,1.6,3,3.1,4.2v-2l1.9-1.5C4.3,15.3,3.5,14.2,3.1,13
                        z"/>
                        <path fill="#ffffff" d="M14.3,18c-1.6,0.3-3.3,0.3-4.8,0l-0.7,2c0.9,0.2,1.8,0.3,2.8,0.3c-1,0-1.9-0.1-2.8-0.3v2
                        c1,0.2,2.1,0.3,3.1,0.3c1.1,0,2.1-0.1,3.1-0.3v-2c-0.5,0.1-1,0.2-1.6,0.2c0.5,0,1-0.1,1.6-0.2L14.3,18z M12,20.3
                        C12,20.3,11.9,20.3,12,20.3c0.3,0,0.8,0,1.2-0.1C12.8,20.3,12.4,20.3,12,20.3z"/>
                        <path fill="#ffffff" d="M20.9,13c-0.4,1.2-1.3,2.3-2.4,3.2l1.9,1.5v2c1.5-1.1,2.6-2.5,3.1-4.1v-2c0,0,0,0,0,0L20.9,13z"/>
                        <path fill="#ffffff" d="M9.8,4.9c-4.5,0.9-7.2,4.5-6,7.9c1.2,3.5,5.8,5.5,10.3,4.6c4.5-0.9,7.2-4.5,6-7.9S14.3,4,9.8,4.9z M13.9,16.8
                        c-4.1,0.8-8.3-1-9.4-4.2C3.5,9.5,5.9,6.3,10,5.5s8.3,1,9.4,4.2C20.5,12.8,18,16,13.9,16.8z"/>
                </g>
                <g>
                <path fill="none" d="M23.7,13.2C23.7,13.1,23.7,13.1,23.7,13.2c0,0.1-0.1,0.2-0.1,0.4C23.6,13.4,23.6,13.3,23.7,13.2z"/>
                    <path fill="none" d="M9.8,4.9c-4.5,0.9-7.2,4.5-6,7.9c1.2,3.5,5.8,5.5,10.3,4.6c4.5-0.9,7.2-4.5,6-7.9C19,6.1,14.3,4,9.8,4.9z
                    M14,16.9c-4.1,0.8-8.3-1-9.4-4.2S5.9,6.3,10,5.5c4.1-0.8,8.3,1,9.4,4.2C20.5,12.8,18.1,16,14,16.9z"/>
                    <path fill="none" d="M23.9,12.2c0,0,0-0.1,0-0.2c0,0.1,0,0.2,0,0.3C23.9,12.2,23.9,12.2,23.9,12.2z"/>
                    <path fill={changeStyle(amount)} d="M10,5.5c-4.1,0.8-6.5,4-5.5,7.2s5.3,5,9.4,4.2c4.1-0.8,6.5-4,5.5-7.2C18.3,6.5,14.1,4.7,10,5.5z"/>
                    <path fill={changeStyle(amount)} d="M24,11.4c0-0.8-0.1-1.7-0.4-2.6l-2.6,0.5c0,0,0,0,0,0c-0.4-1.2-1.3-2.3-2.4-3.2l1.9-1.5
                    c-1.5-1.1-3.3-2-5.4-2.4l-0.7,2C12.9,4,11.3,4,9.6,4.3c0,0,0,0,0,0c0,0,0,0,0,0l-0.7-2C6.8,2.7,5,3.6,3.6,4.7l1.9,1.5c0,0,0,0,0,0
                    c-1.1,0.9-2,2-2.4,3.2c0,0,0,0,0,0L0.5,8.8C0.2,9.6,0,10.5,0,11.4c0,0,0-0.1,0-0.1l0,2c0,0.8,0.1,1.6,0.4,2.3v-2L3.1,13
                    c0,0,0,0,0,0c0,0,0,0,0,0c0.4,1.2,1.3,2.3,2.4,3.2c0,0,0,0,0,0l-1.9,1.5c0,0,0,0,0,0v0l0,0c0,0,0,0,0,0v2c1.5,1.1,3.3,2,5.4,2.4v-2
                    l0.7-2c0,0,0,0,0,0c1.5,0.3,3.2,0.4,4.8,0c0,0,0,0,0,0c0,0,0,0,0,0l0.7,2c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0v2
                    c2.1-0.4,3.9-1.2,5.4-2.3v-2c0,0,0,0,0,0l-1.9-1.5c1.1-0.9,2-2,2.4-3.2l2.6,0.6c0,0,0,0,0,0v2c0.3-0.8,0.4-1.6,0.4-2.4v-2
                    C24,11.2,24,11.3,24,11.4z M14.1,17.4C9.6,18.3,5,16.3,3.8,12.8c-1.2-3.5,1.5-7,6-7.9C14.3,4,19,6.1,20.2,9.5
                    C21.4,13,18.7,16.5,14.1,17.4z M23.7,13.2c0,0.1-0.1,0.2-0.1,0.4C23.6,13.4,23.7,13.2,23.7,13.2C23.7,13.1,23.7,13.1,23.7,13.2z
                    M23.9,12.2c0,0,0,0.1,0,0.1c0-0.1,0-0.2,0-0.3C23.9,12.1,23.9,12.1,23.9,12.2z"/>
                </g>
                <path opacity="0.4" d="M23.9,12.2c0,0.3-0.1,0.7-0.2,1c0,0.1-0.1,0.3-0.1,0.4c-0.6,1.6-1.6,3-3.1,4.1c-1.5,1.1-3.6,2-5.4,2.4
                    c-1,0.2-2,0.3-3.1,0.3c-1.1,0-2.1-0.1-3.1-0.3c-2.1-0.5-3.9-1.3-5.4-2.4C2,16.5,1,15.1,0.4,13.5v0c0-0.1-0.1-0.2-0.1-0.3
                    c-0.1-0.3-0.2-0.7-0.2-1c0-0.3-0.1-0.7-0.1-1l0,2c0,0.8,0.1,1.5,0.4,2.2v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0.5,1.6,1.6,3,3.1,4.1
                    c1.5,1.1,3.3,2,5.4,2.4c1,0.2,2.1,0.3,3.1,0.3c1.1,0,2.1-0.1,3.1-0.3c2.1-0.4,3.9-1.2,5.4-2.3c1.5-1.1,2.6-2.5,3.1-4.1
                    c0.3-0.8,0.4-1.6,0.4-2.4v-2C24,11.5,23.9,11.9,23.9,12.2z"/>
                <text x="50%" y="50%" fill="white" dominantBaseline="middle" textAnchor="middle" fontWeight="bold"
                        fontSize={parseChipValue(amount).length === 6 ? 
                          4 : parseChipValue(amount).length === 5 ? 
                          5 : parseChipValue(amount).length === 4 ? 
                          6 : 6.5} 
                        fontFamily="Ubuntu">{parseChipValue(amount)}</text>
            </svg>
        </div>
    )
}
export default TableChip;