import ActionButton from "../actionButton/ActionButton";
import { useDispatch } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import { t } from "i18next";
import IconChat from "../../../../assets/icons/IconChat.svg";

const Chat = () => {
    const dispatch = useDispatch();

    return (
        <ActionButton 
            onClick={() => {
                dispatch(frontActions.updateHistoryOpened(false))
                dispatch(frontActions.toggleChatOpened())
            }}
            popoverContent={t('chat')}
        >
            <img src={IconChat} alt="icon-chat" />
        </ActionButton>
    )
}

export default Chat;