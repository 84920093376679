import React from "react";
import './App.scss';
import { Route, Routes } from "react-router-dom";
import Game from './components/Game';
import Belote from "./components/front/Belote";
import { ConfigProvider, theme, Card  } from "antd";
import { useSelector } from "react-redux";
import GamesView from "./components/public/GamesView";

const darkMode = {
  colorPrimary: '#fff',
  bgColor: "#12152b",
  colorBgLayout: "#12152b",
  colorTextHeading: "#fff",
  colorText: "#fff",
  layoutHeaderBgColor: "#272a3e"
}

const lightMode = {
  colorPrimary: '#000',
  bgColor: "#fff",
  colorBgLayout: "#fff",
  colorTextHeading: "#000",
  colorText: "#000",
  layoutHeaderBgColor: "#fff"
}

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const isDarkMode = useSelector((state) => state.table.themeMode)

  return (
    <>
      <ConfigProvider className="App" theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: isDarkMode ? darkMode : lightMode,
      }}>
          <Routes>
            <Route index path='/dealer' Component={Game}/>
            {/* <Route index path='/' Component={Game}/> */}
            <Route index path="/" Component={Belote} />
            <Route index path="/games/:id" Component={GamesView} />
          </Routes>
      </ConfigProvider>
    </>
  );
}

export default App;
