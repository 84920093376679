import { Col, Row, Popover } from "antd";
import SoundManager from "../../../../helpers/SoundManager";
import "./ActionButton.scss";

const ActionButton = ({children, callback, onClick = () => {}, popoverContent, placement, trigger="hover"}) => {

    return (
        <Row className="icon-button" onClick={(e) => {
            SoundManager.play('click');
            onClick(e)
        }}>
            <Col span={24} className="clickable-button" onClick={callback}> 
                <Popover content={popoverContent} placement={placement} trigger={trigger}>
                    {children}
                </Popover>
            </Col>
      </Row>
    )
}

export default ActionButton;