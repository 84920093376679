import { Button, Col, Row, Typography, Slider } from "antd";
import { t } from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frontActions } from "../../../store/slice/frontSlice";
import MovableWindow from "../movableWindow/MovableWindow";
import "./Settings.scss";
import SoundManager from "../../../helpers/SoundManager";
import { current } from "@reduxjs/toolkit";
import { isMobileDevice } from "../../../helpers/helpers";

export const RoundOpenApi = createContext('RoundOpenApi');

const SettingsContent = ({closeCallBack}) => {
    const front = useSelector(store => store.front);
    const volume = front.volume.value;
    const gameSound = front.gameSound.value;

    const dispatch = useDispatch();
    const [videoResolutionKey, setVideoResolutionKey] = useState('');
    const [hidePLayersMessages, setHidePLayersMessages] = useState(false);

    const toggleHidePLayersMessages = () => {
        setHidePLayersMessages(!hidePLayersMessages);
    };

    const changeStramSound = (val) => {
        changeSound(val);
        changeGameSound(val);
        
    };
    
    const changeSound = (val) => {
        dispatch(frontActions.updateVolume(val));
        
        const customEvent = new CustomEvent('changeVolume', {
            value: val
        })
        window.dispatchEvent(customEvent);
    };
    
    const changeGameSound = (val) => {
        dispatch(frontActions.updateGameSound(val));
        const customEvent = new CustomEvent('changeGameSound', {
            detail: {
                value: val
            }
        })
        window.dispatchEvent(customEvent);
    };

    const setResolution = (value) => {
        setVideoResolutionKey(value.toLowerCase());
    };

    const close = () => {
        dispatch(frontActions.updateChatOpened(false))
        closeCallBack()
    }
    const SeetingsContentHTML = () => {
        return (
            <div className="settings-box">
                {/* First Block */}
                <div className="settings-blocks first-block">
                    <div className="block-title">General</div>
                    <div className="block-divider"></div>
                    <div className="window-close-button" onClick={close}>&times;</div>
                </div>
        
                {/* Second Block */}
                <div className="settings-blocks second-block">
                <div className="block-title">Video</div>
                <div className="block-divider"></div>
                <div className="block-content">
                    {Object.keys(front.resolutions).map((value, key) => {
                        const activeClassName = (value.toLowerCase() === front.videoResolution) ? 'active': '';

                        return ((
                            <div className="col col-4" key={`resolution-${key}`}>
                                <div className={`settings-button ${activeClassName}`} onClick={() => {
                                    SoundManager.play('click')
                                    dispatch(frontActions.changeVideoResolution(value.toLowerCase()))
                                }}>
                                    <div className="button-label " style={{textTransform: "capitalize"}}>
                                        {value}
                                    </div>
                                </div>
                            </div>
                        ))
                    })}
                </div>
                </div>
        
                {/* Third Block */}
                <div className="settings-blocks third-block">
                <div className="block-title">Sound</div>
                <div className="block-divider"></div>
                <div className="block-content">
                    <div className="col col-3">
                        <div className="volume-box-label">Volume</div>
                        <div className="volume-box">
                            <Slider
                                range
                                min={0}
                                max={100}
                                value={volume}
                                className="sound-slider"
                                onChange={(val) => {
                                    changeSound(val[0])
                                }}
                            />
                        </div>
                    </div>
                    <div className="col col-3">
                        <div className="volume-box-label">Stream Volume</div>
                        <div className="volume-box">
                        <Slider
                                range
                                min={0}
                                max={100}
                                value={volume}
                                className="sound-slider"
                                onChange={(val) => {
                                    changeStramSound(val[0])
                                }}
                            />
                        </div>
                    </div>
                    <div className="col col-3">
                        <div className="volume-box-label">Game Sound</div>
                        <div className="volume-box">
                            <Slider
                                range
                                min={0}
                                max={100}
                                value={gameSound}
                                className="sound-slider"
                                onChange={(val) => {
                                    changeGameSound(val[0])
                                }}
                            />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
    if (!isMobileDevice()) {
        return (
            <SeetingsContentHTML />
        )
    }
    return (<MovableWindow title={t('settings')} closeCallBack={() =>  {
        dispatch(frontActions.updateChatOpened(false))
        closeCallBack()
        }}>
            <SeetingsContentHTML />
        </MovableWindow>
    );

}

export default SettingsContent;