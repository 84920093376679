import { useId, useState } from "react";
import SoundManager from "../../../helpers/SoundManager";
import "./MovableWindow.scss";

const MovableWindow = ({title, closeCallBack, children}) => {
    const id = useId();
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const moveWindow = ($event) => {
        $event.preventDefault();
        
        if (isMouseDown) {
          let deltaX = $event.movementX;
          let deltaY = $event.movementY;
      
          let rect = document.getElementById(id);
      
          if (rect && rect.getBoundingClientRect && typeof rect.getBoundingClientRect === 'function') {
            let loc = rect.getBoundingClientRect();
            let documentHeight = window.innerHeight;
            let documentWidth = window.innerWidth;
      
            let parent = document.getElementById('root');
            let parentSize = parent.getBoundingClientRect();

            let deltaHeight = Math.abs(documentHeight - parentSize.height);
            let deltaWidth = Math.abs(documentWidth - parentSize.width);
      
            if (rect.style) {
              let elementPositionX = offsetX + deltaWidth + $event.clientX + loc.width;
              let elementPositionY = offsetY + deltaHeight + $event.clientY + loc.height;
      
              if (elementPositionX > documentWidth) {
                rect.style.left = documentWidth - loc.width - deltaWidth + 'px';
              } else if (loc.x + deltaX - deltaWidth / 2 < 0) {
                rect.style.left = '0px';
              } else {
                rect.style.left = offsetX + $event.clientX + 'px';
              }
      
              if (elementPositionY > documentHeight) {
                rect.style.top = documentHeight - loc.height - deltaHeight + 'px';
              } else if (loc.y + deltaY - deltaHeight / 2 < 0) {
                rect.style.top = '0px';
              } else {
                rect.style.top = offsetY + $event.clientY + 'px';
              }
            }
          }
        }
    };      

    const dragEelement = ($event) => {
        let rect = document.getElementById(id);
        if ( rect ) {
          setIsMouseDown(true);
          setOffsetX(rect.offsetLeft - $event.clientX);
          setOffsetY(rect.offsetTop - $event.clientY);
        }
    }
    const click = () => {
      SoundManager.play('click');
      closeCallBack()
    }
    return (
        <div className="movable-window" id={id} onMouseMove={moveWindow}>
            <div className="movable-window-top" onMouseDown={dragEelement} onMouseUp={() => setIsMouseDown(false)}>
                <div className="window-move-button">&#x268C;</div>
                <div className="window-title">{title}</div>
                <div className="window-close-button" onClick={click}>&times;</div>
            </div>
            {children}
        </div>
    )
}

export default MovableWindow;