import { Layout, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import GameTableHeader from "./GameTableHeader/GameTableHeader";
import "./GameTable.scss";
import { useSelector } from "react-redux";
import Belote from "../Games/Belote/Belote";
import GameTableFooter from "./GameTableFooter/GameTableFooter";

const GameTable = () => {
    const { Title } = Typography;
    const tableStore = useSelector((store) => store.table);
  
    return(<Layout className="game-table">
        <Header className="header">
            <GameTableHeader />
        </Header>
        <Content className="content">
            {(tableStore?.table?.game?.identifier === 'belote') && <Belote />}
        </Content>
        <Footer className="footer">
           <GameTableFooter />
        </Footer>
    </Layout>)
}

export default GameTable;