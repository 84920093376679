import { t } from "i18next";
import "./Maintanice.scss";

const Maintanice = () => {
    return (
        <div className="game-container-offline">
            <div className="offline-text">{ t('tableOffline')}</div>
        </div>
    )
}

export default Maintanice;