import React from "react";
import classNames from "classnames";
import "./index.scss";
// type CallbackFunction = (...args: any[]) => void;
// type modalSizes = "default" | "content-size";
// type modalBackgrounds = "transparent" | "light-background" | "dark-background";
// type modalAppearances = "default" | "compact";
// type modalPositions = "center" | "top" | "bottom";

// interface IProps {
//   visible?: boolean;
//   size?: modalSizes;
//   background?: modalBackgrounds;
//   appearance?: modalAppearances;
//   position?: modalPositions;
//   closable?: boolean;
//   title?: string;
//   width?: number;
//   zIndex?: number;
//   children?: React.ReactElement;
//   modalBodyClassName?: string;
//   modalContentClassName?: string;
//   className?: string;
//   onClose?: CallbackFunction;
//   buttons?: [];
// }

const Modal = ({
  visible = false,
  size = "default",
  background = "light-background",
  appearance = "default",
  position = "center",
  closable = true,
  title,
  width,
  zIndex,
  children,
  className,
  onClose,
  modalBodyClassName,
  modalContentClassName,
  buttons = [],
}) => {

  if (!visible) {
    return null;
  }

  return (
    <div className={classNames("modal-splash", `${background}`, `a-${appearance}`, `p-${position}`, className)} style={{ zIndex }}>
      <div className={classNames("modal-content", `s-${size} ${modalContentClassName ? modalContentClassName : ""}`)} style={{ width }}>
        <div className="modal-head">
          {title && <div className="ellipsis-text">{title}</div>}
          {closable && (
            <span className="modal-close"  onClick={onClose} >X</span>
          )}
        </div>
        {children && <div className={`modal-body ${modalBodyClassName ? modalBodyClassName : ""}`}>{children}</div>}
        {buttons.length ? (
          <div className="modal-footer">
            {buttons.map(({ name, callBack }) => {
              return (
                <button onClick={callBack} key={name} >
                  {name}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
