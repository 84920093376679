import FlashphonerComponent from "../FlashphonerComponent/FlashphonerComponent";
import { Col, Row, Typography} from "antd";
import TrumpCard from "../../Games/Belote/TrumpCard";
import { t } from "i18next";
import { useSelector } from "react-redux";
import RenderCard from "../../partial/RenderCard/RenderCard";
import StatusContainer from "./statusContainer/StatusContainer";
import Footer from "./footer/Footer";
import BettingActions from "./bettingActions/BettingActions";
import Menu from "./menu/Menu";
import BetBox from "../content/betBox/BetBox";
import Sound from "./sound/Sound";
import AdditionalBets from "../additionalBets/AdditionalBets";
import "./Mobile.scss";

const Mobile = ({bet}) => {
    const frontStore = useSelector((store) => store.front);
    const game = frontStore?.game;
    const coefficient = game?.resultInfo?.coefficient;
    const {Title} = Typography;

    const getPlayerHandClass = (player) => {
        return '';
       // return parseInt(currentRound.hand) === player ? 'hand-active': '';
    }
 
    return (
        <Row className="game-container game-front game-front-mobile" id="game-container-1">
            <div className="game-wrapper">
                <StatusContainer frontStore={frontStore}/>
                <Sound />
                <div className="video-wrapper">
                    <FlashphonerComponent
                        streamId={frontStore?.game?.table?.game?.streamId}
                        streamKey={frontStore?.streamKey}
                        activeResolution={frontStore.activeResolution}
                        resolutions={frontStore.resolutions}
                        volume={frontStore.volume}
                    />
                </div>
                <AdditionalBets coefficient={coefficient} frontStore={frontStore} bet={bet}/>
                {['Betting'].includes(game.currentConf?.stage)? <Row className="bet-box-wrapper">
                    <Col span={8}>
                        <BetBox boxIndex={3} coefficient={coefficient?.player2?.win} frontStore={frontStore} bet={bet}/>
                    </Col>
                    <Col span={8}>
                        <BetBox boxIndex={2} coefficient={coefficient?.draw} frontStore={frontStore} bet={bet}/>
                    </Col>
                    <Col span={8}>
                        <BetBox boxIndex={1} coefficient={coefficient?.player1?.win} frontStore={frontStore} bet={bet}/>
                    </Col>
                </Row>:""}
                
                <div className="content mobile-cards">
                    <BettingActions frontStore={frontStore}/>
                    <Row className="cards-wrapper cards-wrapper-p2">
                        <Col span={24} className={`player-title-wrapper ${getPlayerHandClass(1)}`}>
                            <Title level={4}>Score: <span>{game.resultInfo?.score?.player2 ?? 0}</span></Title>
                        </Col>
                        <Col span={24} className="cards-section">
                            {
                                [3,4,5,6,7,8].map((e, i) => {
                                    return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" frontStore={frontStore} />)
                                })
                            }
                        </Col>
                        <Col span={24} className="cards-section">
                            {
                                [0,1,2].map((e, i) => {
                                    return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" frontStore={frontStore} />)
                                })
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Title>{t('Trump')}</Title>
                        <TrumpCard game={game} />
                    </Row>
                    <Row className="cards-wrapper cards-wrapper-p1">
                        <Col span={24} className={`player-title-wrapper ${getPlayerHandClass(1)}`}>
                            <Title level={4}>Score: <span>{game.resultInfo?.score?.player1 ?? 0}</span></Title>
                        </Col>
                        <Col span={24} className="cards-section">
                            {
                                [3,4,5,6,7,8].map((e, i) => {
                                    return (<RenderCard cardNumber={e} key={"p1"+e+i} i={i} player="p1" frontStore={frontStore}/>)
                                })
                            }
                        </Col>
                        <Col span={24} className="cards-section">
                            {
                                [0,1,2].map((e, i) => {
                                    return (<RenderCard cardNumber={e} key={"p1"+e+i}  i={i} player="p1" frontStore={frontStore} />)
                                })
                            }
                        </Col>
                    </Row>
                </div>
                <Menu />
                <Footer frontStore={frontStore}/>
            </div>
        </Row>
    )
}

export default Mobile;