import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../actionButton/ActionButton";
import SettingsContent from "../../settings/Settings";
import IconSettings from "../../../../assets/icons/IconSettings.svg"
import "./Settings.scss"

const Settings = () => {
    const dispatch = useDispatch();
    const frontStore = useSelector((store) => store.front);


    return <ActionButton
        onClick={() => {
        }}
        trigger="click"
        popoverContent={<div onClick={(e) => {e.stopPropagation();}}>
            <SettingsContent />
        </div>}>
        <img src={IconSettings} alt="settings-icon" />
    </ActionButton>
}

export default Settings;