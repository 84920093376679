import { createSlice } from "@reduxjs/toolkit";
import authData from "../../services/authData";
export const initialState = {
    isLoaded: false,
    table: null,
    isLoggedin: false,
    hasError: false,
    errorMessage: "",
    themeMode: true,
    language: "en-US"
};

const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        updateTable(state, action) {
            state.isLoaded = true;
            const {payload} = action;
           
            if (payload?.table) {
                state.table = payload.table;
            }
            state.isLoggedin = payload.isLoggedin;
            if (payload.accessToken) {
                authData.set(payload.accessToken, true)
            }
        },
        swithcThemeMode(state) {
            state.themeMode = !state.themeMode
        },
        setThemeMode(state, action) {
            state.themeMode = action.payload;
        },
        setLanguage(state, action) {
            state.language = action.payload;
        }
    },
    extraReducers: (builder) => {

    }
});

export const tableActions = tableSlice.actions;
export const tableReducer = tableSlice.reducer;