import { Row, Col, Table } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FrontSocketContext from "../../../store/FrontSocketContext";
import { betsHistoryActions } from "../../../store/slice/betsHistory";
import HistoryRow from "./HistoryRow";

const HistoryTable = ({history}) => {
    const { t } = useTranslation("translation");
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);
    const socket = useContext(FrontSocketContext);
    const dispatch = useDispatch();
    const betsHistory = useSelector((store) => store.betsHistory);
    const page = betsHistory.nextPage;

    const handleScroll = () => {
        const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
        if (scrollHeight - scrollTop === clientHeight) {
          fetchData();
        }
    };

    
    const fetchData = () => {
        dispatch(betsHistoryActions.addNewPage())
        socket.emit("getPaginateBetsHistory", (page))
        socket.on("setPaginateBetsHistory", (data) => {
            dispatch(betsHistoryActions.addHistory(data))
        })
    }

    useEffect(() => {
        containerRef.current.addEventListener('scroll', handleScroll);
        return () => {
            containerRef.current && containerRef.current.removeEventListener('scroll', handleScroll);
        };
    }, [loading, page]);
    
    return (history && <table className="history-data">
        <thead>
            <tr className="history-header">
                <th className="text-left">{t('Date')}</th>
                <th className="text-left">{t('Game')}</th>
                <th className="text-right">{t('Bet')}</th>
                <th className="text-right">{t('Win_lose')}</th>
            </tr>
        </thead>
        <tbody className="history-table-content" ref={containerRef}>
            {history.map((betHistory, index) => {
                return (<HistoryRow key={`bet-history-${index}`} betHistory={betHistory} onClick={() => {}}/>)
            })}
        </tbody>
    </table>)
}

export default HistoryTable;