import { useState } from "react";

const BottomBlock = ({value, additional}) => {
    let color = '#ffffff';
    let textOverflow = 48;
    const {player, type} = additional;

    const parseValue = () => {
        if ( value.toString().toLowerCase() === 'fold' ) {
          textOverflow = 47;
          color = '#b71c1c';
        }

        return value;
    }
    const parsedValue = parseValue();
    
    return (
        <svg viewBox="0 0 163 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Goodwin-Mobile-Light" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.3">
            <g id="uo-copy" transform="translate(-320.000000, -259.000000)" fill="#000000" stroke="#FFFFFF" strokeWidth="2">
                <rect id="Combined-Shape-Copy-2" x="321" y="260" width="161" height="68" rx="16"></rect>
            </g>
            </g>
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold"
                fontSize={textOverflow} fontFamily="Ubuntu" fill={color} className={`test-value-${type}-${player}`}>{parsedValue}
            </text>
        </svg>
    );
}

export default BottomBlock;