import { Col, Row } from "antd";
import { t } from "i18next";
import "./Footer.scss";
import { useSelector } from "react-redux";

const Footer = ({frontStore}) => {
    const frontUserData = useSelector((store) => store.frontUser);
    return (<Row className="mobile-footer">
        <Col>
            {t('balance')}
            <span>{parseFloat(frontUserData?.user?.balance).toFixed(2) ?? 0}</span>
        </Col>
        <Col>
            {t('total_bets')} 
            <span>{frontUserData?.totalBets ?? "0.00"}</span>
        </Col>
    </Row>)
}

export default Footer;