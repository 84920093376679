import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slice/usersSlice";
import { tableReducer } from "./slice/tableSlice";
import { gameReducer } from "./slice/gameSlice";
import { chatReducer } from "./slice/chatSlice";
import { frontReducer } from "./slice/frontSlice";
import { betsHistoryReducer } from "./slice/betsHistory";
import { frontUserReducer } from "./slice/frontUserSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        table: tableReducer,
        game: gameReducer,
        chat: chatReducer,
        front: frontReducer,
        frontUser: frontUserReducer,
        betsHistory: betsHistoryReducer
    }
})