import { useState } from "react";
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import LimtTabelRow from "./LimtTabelRow";

const Limits = ({coefficient}) => {
    console.log("coefficientcoefficient ", coefficient)
    const [limitPinned, setLimitPinned] = useState(false);
    const { t } = useTranslation("translation");

    return (<div className={`limits ${limitPinned ? 'limitPinned': ''}`} onClick={() => setLimitPinned(value => !value)} v-if="userLimits">
        <span className="game-name">Bet on Belote</span>
            <span className="game-limits">
            100.00 - 500 000.00
            </span>
            <div className="pin-button">
                {limitPinned?  <PushpinFilled style={{ fontSize: '12px', color: '#fff' }} />: <PushpinOutlined style={{ fontSize: '12px', color: '#fff' }} />}
            </div>
            <div className={`limits-table ${limitPinned ? 'limitPinned': ''} history-wrapper`}>
                <table className="history-data">
                    <thead>
                        <tr className="history-header">
                            <th className="text-left">{t('bet')}</th>
                            <th className="text-left">{t('min')}</th>
                            <th className="text-right">{t('max')}</th>
                        </tr>
                    </thead>
                    <tbody className="history-table-content">
                        {coefficient && Object.keys(coefficient).map((betKey, index) => {
                            if (betKey == 'draw') {
                                return (<LimtTabelRow key={`bet-${betKey}-${index}`} player={''} coefficientName={betKey} betCoef={coefficient[betKey]} onClick={() => {}}/>)
                            }
                            return Object.keys(coefficient[betKey]).map((current) => {
                                return (<LimtTabelRow key={`bet-${betKey}-${current}-${index}`} player={betKey} coefficientName={current} betCoef={coefficient[betKey][current]} onClick={() => {}}/>)
                            })
                        })}
                    </tbody>
                </table>
            </div>
        </div>)
}

export default Limits;