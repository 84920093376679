import { Typography, Input, Col, Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "./GameTableLogin.scss"
import { useContext } from 'react';
import SocketContext from '../../store/SocketContext';
import 'antd-css-utilities/utility.min.css';

const GameTableLogin = () => {
    const { Title } = Typography;
    const socket = useContext(SocketContext);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            socket.emit("tableLogin", {value: event.target.value})
        }
    }
    return (
        <div className="login-wrapper">
            <Row align="middle" className="form-wrapper">
                <Col span={24}>
                <Row>
                    <Col span={24}><Title className='login-title mt-0'>Login</Title></Col>
                </Row>
                <Row>
                    <Col span={8} offset={8}>
                        <Input.Password
                            placeholder="input password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onKeyDown={handleKeyDown}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
    )
}

export default GameTableLogin;