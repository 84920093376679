import { Col, Row } from "antd";
import { t } from "i18next";
import { useContext } from "react";
import { RoundOpenApi } from "./History";

const HistoryRow = ({betHistory, callBack}) => {
    const openRoundHistory = useContext(RoundOpenApi)
    const betDiff = betHistory.totalWinAmount - betHistory.totalAmount;
    const diffClassName = betDiff > 0 ? 'bet-plus-diff' : 'bet-minus-diff';
   
    return (
        <tr className="history-item" onClick={() => openRoundHistory(betHistory)}>
            <td className="text-left">{new Date(betHistory.createdAt).toLocaleString('en-US', { hour12: false })}</td>
            <td className="text-left">{t('belote')}</td>
            <td className="text-right">{betHistory.totalAmount}</td>
            <td className={`text-right ${diffClassName}`}>{betDiff}</td>
        </tr>
    )
}

export default HistoryRow;