import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    history: [],
    nextPage: 2,
    rountBets: [],
    playerMessages: [],
    playerToDealerMessages: []
};

const betsHistorySlice = createSlice({
    name: "betsHistory",
    initialState,
    reducers: {
        setHistory(state, action) {
            state.history = action.payload;
        },
        addHistory(state, action) {
            state.history = state.history.concat(action.payload);
        },
        addLastRoundBetHistory(state, action) {
            state.history = action.payload.concat(state.history );
        },
        setRoundBets(state, action) {
            state.rountBets = action.payload;
        },
        addNewPage(state, action) {
            state.nextPage += 1;
        }
    }
});

export const betsHistoryActions = betsHistorySlice.actions;
export const betsHistoryReducer = betsHistorySlice.reducer;