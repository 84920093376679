import { t } from 'i18next';
import { useState } from 'react';
import ActionButton from '../actionButton/ActionButton';
import IconMaximize from "../../../../assets/icons/IconMaximize.svg";
import IconMinimize from "../../../../assets/icons/IconMinimize.svg";

const Fullscreen = () => {
    const [isFullscreen, setIsFullscreen] = useState(true);
    const toggleFullscreen = () => {
      if (isFullscreen) {
        // Request fullscreen
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
  
      setIsFullscreen((prev) => !prev );
    };
  
    return (
      <ActionButton 
        callback={toggleFullscreen} 
        popoverContent={t('full_screen')}
        placement="bottomRight"
      >
        <img src={!isFullscreen ? IconMinimize : IconMaximize } alt="icon-fullscreen" />
      </ActionButton>
    );
}

export default Fullscreen;