import Card from "../../partial/card";

const TrumpCard = ({game}) => {
    let cardName = 'emptyCard';
    const {cards} = game;

    if (cards?.trump?.value) {
        cardName = cards.trump.value;
    }
    if (game.currentConf?.player === 'trump' && !cards?.trump?.value) {
        cardName = 'emptyActiveCard';
    }
    return (
        <Card cardName={cardName} />
    );
}

export default TrumpCard;