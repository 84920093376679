import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: [],
    playerMessages: [],
    playerToDealerMessages: []
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        addMessage(state, action){
            state.messages.unshift(action.payload)
        },
        clearMessages(state) {
            state.messages = [];
        },
        updatePlayerToDealerMessages(state, action) {
            state.playerToDealerMessages = action.payload;
        },
        updatePlayerMessages(state, action) {
            state.playerMessages = action.payload;
        },
        addPlayerMessage(state, action) {
            state.playerMessages.unshift(action.payload)
        },
        addPlayerToDealerMessage(state, action) {
            state.playerToDealerMessages.unshift(action.payload)
        },
        deletePlayerToDealerMessage(state, action) {
            state.playerToDealerMessages = state.playerToDealerMessages.filter((current) => {
                return current.id !== action.payload;
            })
            state.playerMessages = state.playerMessages.filter((current) => {
                return current.id !== action.payload;
            })
        }
    }
});

export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;