import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
    user: {
        name: "eee"
    }
};

export const isUserLoggedin = createAsyncThunk('users/isLoggedin', async() => {
    return 554;
})

const usersSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(isUserLoggedin.fulfilled, (state, action) => {
    
        })
    }
});

export const userActions = usersSlice.actions;
export const userReducer = usersSlice.reducer;