import { Button, Col, Row, Typography, message } from "antd";
import { t } from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frontActions } from "../../../store/slice/frontSlice";
import MovableWindow from "../movableWindow/MovableWindow";
import "./History.scss";
import HistoryTable from "./HistoryTable";
import RoundHistory from "./RoundHistory/RoundHistory";
import FrontSocketContext from "../../../store/FrontSocketContext";

export const RoundOpenApi = createContext('RoundOpenApi');

const History = ({closeCallBack = () => {}}) => {
    const front = useSelector(store => store.front);
    const socket = useContext(FrontSocketContext)
    const dispatch = useDispatch();

    const [openHistory, setOpenHistory] = useState(false);
    const [roundData, setRoundData] = useState(null);

    const betsHistory = useSelector((store) => store.betsHistory);
    const history = betsHistory.history;

    const backCallBack = () => {
        setRoundData(null);
    }

    const openRoundHistory = (roundData) => {
        setRoundData(roundData);
    }
    useEffect(() => {
       if (!front.historyOpened) {
        setRoundData(null);
       }
       else {
            socket.emit("getBetsHistory")
       }
    }, [front.historyOpened]);
    
    if (!front.historyOpened) {
        return '';
    }

    return (<MovableWindow title={t('history')} closeCallBack={() =>  {
        dispatch(frontActions.updateHistoryOpened(false))
        closeCallBack()
    }}>
        <Row className="history-wrapper">
            <Col span={24}>
                {front.historyOpened && <Row>
                    <Row className="history-content">
                        <Col span={24}>
                            {!history.length? (<Typography style={{textAlign: "center"}}>{t('empty_history')}</Typography>): !roundData ?  <RoundOpenApi.Provider value={openRoundHistory}>
                                <HistoryTable history={history} />
                            </RoundOpenApi.Provider>: <RoundHistory roundData={roundData} backCallBack={backCallBack}/>}
                            {}
                        </Col>
                    </Row>    
                </Row>}
            </Col>
        </Row>
    </MovableWindow>)

    //return ()
}

export default History;