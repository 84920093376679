import { Row, message } from "antd";
import { t } from "i18next";
import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import FrontSocketContext from "../../../store/FrontSocketContext";
import TableChip from "../content/tableChip/TableChip";
import IconTips from '../../../assets/icons/IconTips.svg'
import IconLeftArrow from '../../../assets/icons/IconLeftArrow.svg'
import IconRightArrow from '../../../assets/icons/IconRightArrow.svg'
import "./TipBox.scss";

const TipBox = () => {
    const [messageApi, tipMessageHolder] = message.useMessage();
    const socket = useContext(FrontSocketContext)
    const frontStore = useSelector((store) => store.front);
    const selectedAmount = frontStore.selectedChip;
    const [amount, setAmount] = useState([selectedAmount]);
    const getAmount = () => amount.reduce((partialSum, a) => partialSum + a, 0);

    const gameConfig = frontStore.integration.gameConfig;

    const addAmount = useCallback((amountVal) => {

        setAmount((prev) => [...prev, amountVal]);
    }, []);

    const deleteLastTip = () => {
      
        if (amount.length >= 2) {
            setAmount(prevArray => {
                return prevArray.slice(0, prevArray.length - 1);
            });
        }
    }
    const sendTip = () => {
        socket.emit("sendTip", {amount: getAmount()})
        setAmount([selectedAmount])
    }
  
    return (<Row>
        {tipMessageHolder}
        <div className="tip-box">
            <div className="name-wrapper">
            <div className="tips">
                <img src={IconTips} alt="React Logo" />
                <span>{t('TIPS')}</span>
            </div>
            <div className="name">
                <span>{t('Dealer')}</span>
                <p>{frontStore?.game?.table?.dealer?.nickName}</p>
            </div>
            </div>
            <div className="hidden-block">
            <div className="counter">
                <img src={IconLeftArrow} className="action-button" onClick={deleteLastTip} />
                <TableChip amount={getAmount()}/>
                <img src={IconRightArrow} className="action-button" onClick={() => addAmount(selectedAmount)} />
            </div>
            <button onClick={sendTip}>{t('send_tip')}</button>
            </div>
        </div>
    </Row>)
}

export default TipBox;