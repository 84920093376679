import { Col, Row, Typography, Space, Button } from "antd";
import "./Belote.scss";
import Card from "../../partial/card";
import { useSelector } from "react-redux";
import TrumpCard from "./TrumpCard";
import Combinations from "./Combinations";
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import SocketContext from "../../../store/SocketContext";

const getPlayerCombination = (list) => {
    const combinations = [];
    if (!list) {
        return false;
    }
    Object.keys(list).forEach((current) => {
        if (list[current].length && current !== 'status') {
            combinations.push(current);
        }
    });
    if (!combinations.length) {
        return false;
    }
    return combinations;
}

const Belote = () => {
    const game = useSelector(state => state.game);

    const {cards, resultInfo, currentRound} = game;
    const coefficient = resultInfo?.coefficient;
    const socket = useContext(SocketContext);
    const { t } = useTranslation("translation");

    const {Title, Text} = Typography;
    const getPlayerHandClass = (player) => {
        return parseInt(currentRound.hand) === player ? 'hand-active': '';
    }

    const getCardCombinationCalss = (player, cardValue) => {
        if (!resultInfo?.combination || resultInfo?.combination[player]) {
            return '';        
        }
        let playerStr = player === 'p1' ? 'player1':  'player2';
        let className = '';

        const data = getPlayerCombination(resultInfo?.combination[playerStr]);
        if (!data) {
            return '';
        }
        const getCardStr = (currentCard) => {
            return (currentCard.rank != 10? currentCard.rank: 'T')+(currentCard.suit?.at(0).toUpperCase())
        }

        data.forEach(combinationName => {
            resultInfo?.combination[playerStr][combinationName].forEach((combinationCards) => {
                if (combinationCards) {
                    if (combinationName !== 'belote') {
                        const data = combinationCards?.find(currentCard => {
                            return getCardStr(currentCard) === cardValue;
                        })
                        if (data) {
                            className +=  ` card-combination ${combinationName}`
                        }
                    }
                    else if (combinationName === 'belote' && getCardStr(combinationCards) === cardValue) {
                        className +=  ` card-combination ${combinationName}`
                    }
                }
            });
        });

        return className;
    }

    const RenderCard = ({cardNumber, i, player}) => {
        if (!cards) {
            return '';
        }
        let cardName = 'emptyCard';
        if (!!cards[player] && cards[player][cardNumber]) {
            cardName = cards[player][cardNumber].value;
        }
        if (game.currentConf?.player === player && game.currentConf?.card-1 === (cardNumber) && cardName === 'emptyCard') {
            cardName = 'emptyActiveCard';
        }
        const className = getCardCombinationCalss(player, cardName);
        return (
            <Card key={player+i} cardName={cardName} className={className}/>  
        )
    }
    
    return (<Row className="belot-board-content">
        <Col span={11} className="players-wrapper">
            <Row>
                <Col span={8} className={`player-title-wrapper ${getPlayerHandClass(1)}`}>
                    <Title level={2}>P1</Title>
                    <Title level={4}>Score: <span>{game.resultInfo?.score?.player1 ?? 0}</span></Title>
                </Col>
                <Col span={8} className="player-title-wrapper player-score">
                    {coefficient?.player1 && Object.keys(coefficient?.player1).map((current, i) => {
                        return (
                            <Space key={'p1'+current+''+i}>
                                <Text>{t(current)}</Text>
                                <Title level={4}>{coefficient.player1[current]}</Title>
                            </Space>
                        )
                    })}
                </Col>
            </Row>
            <Row className="cards-wrapper">
                <Col span={24} className="cards-section">
                    {
                        [0,1,2].map((e, i) => {
                            return (<RenderCard key={"p1"+e+i} cardNumber={e} i={i} player="p1" />)
                        })
                    }
                </Col>
                <Col span={24} className="cards-section">
                    {
                        [3,4,5,6,7,8].map((e, i) => {
                            return (<RenderCard key={"p1"+e+i} cardNumber={e} i={i} player="p1" />)
                        })
                    }
                </Col>
            </Row>
            <Combinations combinations={getPlayerCombination(resultInfo?.combination?.player1)} player="p1" canceled={!!resultInfo?.combination?.player1?.status}/>
        </Col>
        <Col span={2} style={{textAlign: "center"}}>
            <Space>
                <Text level={2}>Draw</Text>
                <Title level={4} style={{margin: 0}}>{resultInfo?.coefficient?.draw}</Title>
            </Space>
            
            <Title className="trump-title">{t('Trump')}</Title>
            <TrumpCard game={game} />
            {game.waitingApprove ? <Col span={24} >
                <Typography>{t('waiting_to_approve')}</Typography>
                <Button onClick={() => socket.emit('dealerApprove')} className="go-color">{t('Approve')}</Button>
            </Col>: ''}
        </Col>
        <Col span={11} className="players-wrapper player2-wrapper">
            <Row className="player2-info-wrapper">
                <Col span={8} className={`player-title-wrapper ${getPlayerHandClass(2)}`}>
                    <Title level={2}>P2</Title>
                    <Title level={4}>Score: <span>{game.resultInfo?.score?.player2 ?? 0}</span></Title>
                </Col>
                <Col span={8} className="player-title-wrapper player-score">
                    {coefficient?.player1 && Object.keys(coefficient?.player2).map((current, i) => {
                        return (
                            <Space key={'p1'+current+''+i}>
                                <Text>{t(current)}</Text>
                                <Title level={4}>{coefficient.player2[current]}</Title>
                            </Space>
                        )
                    })}
                </Col>
            </Row>
            <Row className="cards-wrapper">
                <Col span={24} className="cards-section">
                    {
                        [0,1,2].map((e, i) => {
                            return (<RenderCard key={"p2"+e+i} cardNumber={e} i={i} player="p2" />)
                        })
                    }
                </Col>
                <Col span={24} className="cards-section">
                    {
                        [3,4,5,6,7,8].map((e, i) => {
                            return (<RenderCard key={"p2"+e+i} cardNumber={e} i={i} player="p2" />)
                        })
                    }
                </Col>
            </Row>
            <Combinations combinations={getPlayerCombination(resultInfo?.combination?.player2)} player="p2" canceled={!!resultInfo?.combination?.player2?.status}/>
        </Col>
    </Row>);
}

export default Belote;