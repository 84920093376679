import ActionButton from "../actionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import {Slider} from "antd";
import "./Sound.scss"
import IconVolumeOff from "../../../../assets/icons/IconVolumeOff.svg";
import IconVolumeOn from "../../../../assets/icons/IconVolumeOn.svg";

const Sound = () => {
    const dispatch = useDispatch();
    const frontStore = useSelector((store) => store.front);

    const volume = frontStore.volume.value;
    const changeSound = (val) => {
        dispatch(frontActions.updateVolume(val));
        const customEvent = new CustomEvent('changeVolume', {
            value: val
        })
        window.dispatchEvent(customEvent);
    };

    return <ActionButton
        onClick={() => {
            changeSound(volume ? 0 : 100);
        }}
        popoverContent={<div className="sound-slider-wrapper" onClick={(e) => {e.stopPropagation();}}>
        <Slider
            range
            min={0}
            max={100}
            value={volume}
            className="sound-slider"
            onChange={(val) => {
                changeSound(val[0])
            }}
        />
    </div>}>
        <img src={!volume ? IconVolumeOff : IconVolumeOn} alt="icon-volume" />
    </ActionButton>
}

export default Sound;