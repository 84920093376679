import "./index.scss"
import React, { useState } from "react";
import { Typography, Space, Button } from "antd";
import {ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons"

const ChatBox = ({messages}) => {
    const initalFontSize = 30;
    const stepIncrease = 2;
    const [playerChatSize, setPLayerChatSize] = useState(initalFontSize)
    return (
        <>
            {/* <Space className="controls">
                <Button className="primary-color" onClick={() => {
                    setPLayerChatSize((prev) => prev += stepIncrease)
                }}><ZoomInOutlined /></Button>
                <Button className="primary-color" onClick={() => {
                    setPLayerChatSize((prev) => prev -= stepIncrease)
                }}><ZoomOutOutlined /></Button>
            </Space> */}
            <React.Fragment >
                {messages && messages.map((messageObj, index) => {
                    return (<Typography className='message' style={{fontSize: `${playerChatSize}px`}} key={`${messageObj.createdAt}-${index}`}>
                        {messageObj.playerUserName ? `${messageObj.playerUserName} - ${messageObj.message}`: messageObj.message}
                    <Typography.Text className="chat-time">{new Date(messageObj.createdAt).toLocaleTimeString()}</Typography.Text></Typography>)
                    
                })}
            </React.Fragment>
        </>
    )
}

export default ChatBox;