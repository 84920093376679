import { FaVolumeHigh, FaVolumeXmark, FaVolumeLow } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import "./Sound.scss"

const Sound = () => {
    const dispatch = useDispatch();
    const frontStore = useSelector((store) => store.front);
    const volume = frontStore.volume.value;

    const changeSound = () => {
        
        const newVolume = volume === 0 ? 100: 0;

        dispatch(frontActions.updateVolume(newVolume))
    }

    return (
        <ul className="right-buttons">
            <li>
                <div className="icon-button sound">
                    <div className="clickable-button" onClick={changeSound}>
                        {volume ? <FaVolumeHigh color="#fff" size={28} />: <FaVolumeXmark color="#fff" size={28} />}
                    </div>
                </div>
            </li>
        </ul>
    )
}

export default Sound;