import { Row } from "antd";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import SoundManager from "../../../../helpers/SoundManager";
import { frontActions } from "../../../../store/slice/frontSlice";
import Chip from "../../content/chip/Chip";
import {RollbackOutlined, CloseOutlined} from "@ant-design/icons";
import "./BettingActions.scss";
import ReturnArrow from "../../../../assets/icons/ReturnArrow.svg"
import FrontSocketContext from "../../../../store/FrontSocketContext";

const BettingActions = ({frontStore}) => {
    const socket = useContext(FrontSocketContext);
    let gameConfig = frontStore.integration.gameConfig;
    const bets = frontStore?.bets;
    
    const betAmounts = gameConfig.CURRENCY_BET_AMOUNTS;
    const selected = frontStore.selectedChip ?? betAmounts[0];
    const dispatch = useDispatch();
    const [openChips, setOpenChips] = useState(false);
    const hideIfNotOpened = !openChips ? '': '';

    const className = frontStore.game?.currentConf?.stage === 'Betting' ? 'show-betting': 'hide-betting';
    const cancelBets = () => {
        socket.emit("removeLastBet");
    }

    function calculateChipLocation(chipIndex) {
        if (openChips) {
          let chipsCount = betAmounts.length;
          let radius = chipsCount * 4;
          let stepX = -(radius * 2);
          let stepY = 58 - (radius * 2.1);
          let angle = ((chipIndex / (chipsCount)) * Math.PI) + Math.PI / (chipsCount * 2.2);
          let x = (radius * Math.cos(angle)) + ((radius * 2) + 9 / 2);
          let y = (radius * Math.sin(angle)) + ((radius * 2) + 9 / 2);
          return {top: stepY + x + 'vmin', right: stepX + y + 'vmin'};
        } else {
          let chipsCount = betAmounts.length;
          let radius = 0.5;
          let stepX = -(radius * 2);
          let stepY = 58 - (radius * 2.7);
          let angle = ((chipIndex / (chipsCount)) * Math.PI) + Math.PI / (chipsCount * 2.2);
          let x = (radius * Math.cos(angle)) + ((radius * 2) + 9 / 2);
          let y = (radius * Math.sin(angle)) + ((radius * 2) + 9 / 2)-1;
        //   console.log(stepX,stepY,x,y)
          return {top: stepY + x + 'vmin', right: stepX + y + 'vmin'};
        }
    }
      
    return (
        <Row className={`betting-actions-mobile-right ${className}`}>
            <div className={`background-drop disable-interaction ${openChips ? 'show': ''}`}></div>
            <div className={`betting-chips ${hideIfNotOpened}`}>
                <ul className={`chips-container ${openChips ? 'chips-open': ''}`}>
                    {betAmounts && betAmounts.map((currentAmount, i) => {
                        return <li key={`current-chip-${currentAmount} ${i}`} style={calculateChipLocation(i)}>
                            <Chip amount={currentAmount} selected={selected === currentAmount} onClick={() => {
                                dispatch(frontActions.setSelectedChip(currentAmount));
                                SoundManager.play("selectChip");
                            }} />
                        </li>
                    })}
                </ul>
            </div>
            <ul className="bottom-actions">
                <li className="icon-button canel-li">
                    <div className="clicable-button">
                        <button className="action-button action-cancel" onClick={cancelBets}>
                            
                            {bets.length > 1 ? <img src={ReturnArrow} />: <CloseOutlined />}
                        </button>
                    </div>
                </li>
                <li className="action-select-chip" onClick={() => setOpenChips((prev) => !prev)}>
                    <Chip value={selected} amount={selected}/>
                    <div className="no-chip">&nbsp;</div>
                </li>
            </ul>
        </Row>
    )
}

export default BettingActions;