import "./AdditionalBetBox.scss";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import TableChip from "../tableChip/TableChip";
import BetCircle from "../betCircle/BetCircle";
import { t } from "i18next";
import SoundManager from "../../../../helpers/SoundManager";

const AdditionalBetBox = ({
    betName,
    coefficient,
    bet,
    frontStore,
    player
}) => {
    const [openFull, setOpenFull] = useState(false);
    const {game} = frontStore;
    const {bets} = frontStore;
    const isBetting = game?.currentConf?.stage === 'Betting';

    const betAmount = bets.reduce((a, current) => {

        if (current.player === player && current.type === betName) {
            a += current.amount;
        }

        return a;
    }, 0);
    useEffect(() => {
        if (betAmount) {
            setOpenFull(true);
        }
        else {
            setOpenFull(false);
        }
    }, [betAmount])
    const expandClass = openFull ? 'expand': '';
    return (
        <div className={`additional-bet-box ${expandClass}`}>
  
        <div className={`bet-table test-${betName}-${player}`}>
        <div className="bet-name">{t(betName)}</div>
        <div className="bet-odd" onClick={() => setOpenFull((prev) => !prev)}>{coefficient}</div>
        <div className={`bet-box`} onClick={() => {
                    if (isBetting) {
                        SoundManager.play("placeChip");
                        bet({
                            player,
                            type: betName,
                        })
                    }
            }}>
            <BetCircle lost={coefficient === 0} win={coefficient === 1}>
                    {isBetting ? t('bet_here'): `-`}
            </BetCircle>
            <TableChip amount={betAmount} /> 
        </div>
        </div>
    </div>
    )
    // return (<Row className="additional-bet" onClick={() => {
    //     if (isBetting) {
    //         bet({
    //             player,
    //             type: betName,
    //         })
    //     }
    // }}>
    //     <TableChip amount={betAmount} /> 
    //     <Col span={4}>{coefficient}</Col>
    //     {openFull && <Col span={20}>{betName}</Col>}
    // </Row>)
}

export default AdditionalBetBox;