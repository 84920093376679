import Loader from "./partial/Loader";
import { Layout, Typography, Card } from 'antd';
import "./Game.scss"
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../store/SocketContext";
import { tableActions } from "../store/slice/tableSlice";
import GameTableLogin from "./GameTableLogin/GameTableLogin";
import GameTable from "./GameTable/GameTable";
import { gameActions } from "../store/slice/gameSlice";
import Modal from "./partial/modal"
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { chatActions } from "../store/slice/chatSlice";

const { Content } = Layout;

const Game = () => {
    const tableStore = useSelector((state) => state.table);
    const socket = useContext(SocketContext)
    const dispatch = useDispatch();
    const { t } = useTranslation("translation");
    const game = useSelector(state => state.game);
    let [openErrorPopup, setOpenErrorPopup] = useState(false);
    let [results, setResults] = useState(false);
    let [mistake, setMistake] = useState(false);
    const score = game.resultInfo?.score ?? {};

    const { Title, Paragraph, Text } = Typography;

    useEffect(() => {
        setOpenErrorPopup(game.error.status);
    }, [game.error.status]);

    useEffect(() => {
        setResults(game?.resultInfo?.finish)
    }, [game?.resultInfo?.finish]);

    useEffect(() => {
        setMistake(game.mistake);
    }, [game.mistake])

    useEffect(() => {
        
        (function (socket) {
            var barcodeEventNums = [];
            document.addEventListener('keydown', function (e) {
                if ( e.key === 'Enter' ) {
                    if (socket) {
                        dispatch(gameActions.resetError())
                        socket.emit('barcodeEvent', barcodeEventNums.join(''));
                    }
                    barcodeEventNums = [];
                } else {
                    if ( e.key && e.key.length === 1 ) { // skip shift alt etc
                        barcodeEventNums.push(e.key);
                    }
                }
                // e.preventDefault();
            }, false);
        })(socket);
    }, [socket])

    const handleDoubleClick  = () => {
        socket.emit('dealerApprove')
    };
    
     useEffect(() => {
        window.addEventListener("dblclick", handleDoubleClick);
        return () => window.removeEventListener("dblclick", handleDoubleClick);
    }, [socket]);
 
    useEffect(() => {        
    
        if (socket) {
            socket.on("connect", () => {
                socket.emit("join", 'Dealer');
            });
            if (tableStore.isLoggedin) {
                socket.emit("dealerInit");
                socket.on("playerChatMessages", (messages) => {
                    dispatch(chatActions.updatePlayerToDealerMessages(messages))
                });
            }
            
            // socket.onAny((event, ...args) => {
            //     dispatch(gameActions.resetError())
            // });
            socket.emit("checkIsLoggedin")
            socket.on("tableUpdate", (data) => {
                dispatch(tableActions.updateTable(data));
            });
            socket.on("tableLoggedIn", (data) => {
     
                const authToken = "Bearer "+data.accessToken;
                socket.auth.token = authToken;
                socket.disconnect();
                
                // Connect to the server with the updated auth token
                socket.connect({
                    auth: {
                        token: authToken
                    }
                });
                socket.emit("join", 'Dealer');
                dispatch(tableActions.updateTable(data));
                socket.emit('refreshGame');
            });
            socket.on("gameUpdate", (data) => {
                dispatch(gameActions.update(data))
            });
            socket.on("gameError", (data) => {
                dispatch(gameActions.error(data.message))
            })
            socket.on("gameResetError", (data) => {
                dispatch(gameActions.resetError())
            })
        }
    }, [socket, dispatch, tableStore.isLoggedin]);

    if (!tableStore.isLoaded) {
        return (<Layout>
            <Loader />
        </Layout>)
    }
   
    return (<Layout className="game-wrapper">
        <Content>
            {!tableStore.isLoggedin ? <GameTableLogin />: <GameTable />}
            {createPortal(<Modal
                visible={openErrorPopup}
                size={"default"}
                background={"dark-background"}
                appearance={"default"}
                position={"center"}
                title={"Error"}
                modalBodyClassName={"error-body"}
                modalContentClassName="error-modal"
                onClose={() => {
                    setOpenErrorPopup(false);
                    dispatch(gameActions.resetError())
                }}
            ><p>{t(game.error.message)}</p></Modal>, document.getElementById('modal'))}
            {
                createPortal(<Modal
                    visible={mistake}
                    size={"default"}
                    background={"dark-background"}
                    appearance={"default"}
                    position={"center"}
                    title={"Mistake"}
                    modalBodyClassName={"error-body"}
                    modalContentClassName="error-modal"
                    onClose={() => {
                        setMistake(false);
                    }}
                >
                    <Typography.Title level="3" style={{color: "#000"}}>{t('mistake_wait_to_fix')}</Typography.Title>
                </Modal>, document.getElementById('modal'))
            }
            {createPortal(<div className="modal-wrapper-Win">
                <Modal
                    visible={results}
                    size={"default"}
                    background={"dark-background"}
                    appearance={"default"}
                    position={"center"}
                    title={"Results"}
                    modalBodyClassName={"winner-body"}
                    modalContentClassName="winner-modal"
                    onClose={() => {
                        setResults(false);
                    }}
                >
                    <Title level={2}>Results time: {game?.currentConf?.current}</Title>
                    <Title level={2}>Winner: {game.resultInfo?.winner}</Title>
                    <Title level={2}>Score: {score && game.resultInfo?.winner != 'draw' ? score[game.resultInfo?.winner]: score['player1']}</Title>
                </Modal>
            </div>, document.getElementById('modal'))}
        </Content>
    </Layout>)
}

export default Game;