import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    requested: false,
    user: {},
    game: {},
    integration: {},
    blocked: null,
    totalBets: 0,
    totalWinAmount: 0,
    error: {
        status: false,
        message: ""
    }
};

const frontUserSlice = createSlice({
    name: "frontUser",
    initialState,
    reducers: {
        setUser(state, action) {
            const data = action.payload;
            state.requested = true;
            state.user = data.user;
            state.integration = data.integration;
            state.blocked = data.blocked;
            if (data.totalBets) {
                state.totalBets = data.totalBets;
            }
            
            if (typeof data.integration.gameConfig === 'string') {
                state.integration.gameConfig = JSON.parse(data.integration.gameConfig);
            }
            if (data.integration.gameConfig.CURRENCY_BET_AMOUNTS && !state.selectedChip) {
                state.selectedChip = data.integration.gameConfig.CURRENCY_BET_AMOUNTS[0];
            }
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        updateTotalBets(state, action) {
            state.totalBets = action.payload;
        },
        updateTotalWinAmount(state, action) {
            state.totalWinAmount = action.payload;
        },
        setError(state, action) {
            state.error.status = true;
            state.error.message = action.payload;
        },
        clearError(state) {
            state.error.status = false;
            state.error.message = '';
        }
    }
});

export const frontUserActions = frontUserSlice.actions;
export const frontUserReducer = frontUserSlice.reducer;