import Storage, { prefix } from "./storage";

/** @constant {string} KEY - Storage key */
const KEY = "PLAYER_AUTH_DATA";
/** @constant {string} SESSION_STORAGE_KEY - sessionStorage key */
const SESSION_STORAGE_KEY = `${prefix}${KEY}`;
/** @constant {number} AUTH_SESSION_LIFETIME */
const AUTH_SESSION_LIFETIME = 864000; // 10 days in seconds
/** @constant {number} SESSION_STORAGE_LIFETIME - For emulating session storage */
const SESSION_STORAGE_LIFETIME = 3600; // 1 hour in seconds

let useSession = !!sessionStorage.getItem(SESSION_STORAGE_KEY);

const set = (data, remember = false) => {
  if (remember) {
    useSession = false;
    Storage.setItem(KEY, data, AUTH_SESSION_LIFETIME);
  } else {
    try {
      useSession = true;
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
    } catch (e) {
      useSession = false;
      Storage.setItem(KEY, data, SESSION_STORAGE_LIFETIME);
    }
  }
};

const get = () => {
  try {
    if (useSession) {
      const data = sessionStorage.getItem(SESSION_STORAGE_KEY);
      return data ? JSON.parse(data) : null; /* todo fix bug after delete */
    } else {
      return Storage.getItem(KEY);
    }
  } catch {}
};

const getAuthToken = () => {
  const data = get();
  return data ? data.auth_token : null;
};

/**
 * Clears auth data
 */
const clear = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY);
  Storage.removeItem(KEY);
};

export default {
  set,
  get,
  getAuthToken,
  clear,
};
