import { Col, Popover, Row, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import Chip from "../chip/Chip";
import Timer from "../timer/Timer";
import "./BetsContainer.scss"
import {RetweetOutlined , CloseOutlined} from "@ant-design/icons";
import SoundManager from "../../../../helpers/SoundManager";
import ReturnArrow from "../../../../assets/icons/ReturnArrow.svg"

const BetsContainer = ({amounts, game, socket}) => {
  
    const dispatch = useDispatch();
    const frontStore = useSelector((store) => store.front);

    if (!amounts) return '';
    const bets = frontStore?.bets;
    const selected = frontStore.selectedChip ?? amounts[0];
    const balance = frontStore?.user?.balance;

    return (<Row>
        <Col span={24}>
            <Typography.Title level={3}>{t('place_bets')}</Typography.Title>
            <Row className="chips-wrapper">
                {amounts.map((currentAmount, i) => {
                    const lowBalance = currentAmount > balance;
                   
                    return <Chip amount={currentAmount} selected={selected === currentAmount} disabled={lowBalance} title={t('low_balance')} onClick={() => {
                        if(lowBalance) return false;
                        dispatch(frontActions.setSelectedChip(currentAmount));
                        SoundManager.play("selectChip");
                    }} key={`current-chip-${currentAmount} ${i}`}/>
                })}
            </Row>
        </Col>
        <Col span={24} className="timer-wrapper">
            <div className="bet-components-wrapper">
                <div className="roound-back-btn repeate_bet" onClick={() => socket.emit("repeateBet")}>
                    <RetweetOutlined />
                </div>
                <Timer duration={game?.currentConf?.duration} secondsLeft={game?.currentConf?.current}/>
                <div className="roound-back-btn" onClick={() => socket.emit("removeLastBet")}>
                    {bets.length > 1 ? <img src={ReturnArrow} />: <CloseOutlined />}
                </div>
            </div>
        </Col>
    </Row>)
}

export default BetsContainer;