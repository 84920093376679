import React, { useState, useEffect } from 'react';
import FrontSocketContext from './FrontSocketContext';
import io from 'socket.io-client';
import { getPlayerToken } from '../helpers/HttpClient';
import Modal from 'react-modal';
import './Modal.css'; // Import CSS file for modal styles


// Set the app element for React Modal
Modal.setAppElement('#root'); // Assuming your root element has an id of 'root'

const FrontSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        const token = getPlayerToken();
        const apiURL = process.env.REACT_APP_API_URL;

        const newSocket = io(`${apiURL}`, {
            transports: ['websocket', 'polling', 'flashsocket'],
            auth: {
                token: token,
                by: 'player',
            },
            reconnection: true,
            reconnectionAttempts: 10,
            reconnectionDelay: 1000,   
            reconnectionDelayMax: 5000, 
        });

        newSocket.on('connect', () => {
            newSocket.on('unauthorized', (data) => {
                // Show modal with error message
                setModalMessage(data.message);
                setModalIsOpen(true);
            });
        });

        setSocket(newSocket);

        return () => newSocket.close();
      }, []);

    return (
        <FrontSocketContext.Provider value={socket}>
            {children}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Error Modal"
                className="custom-modal" // Apply custom CSS class to the modal
            >
                <h2>Error</h2>
                <p>{modalMessage}</p>
                <button onClick={() => setModalIsOpen(false)}>Close Modal</button>
            </Modal>
        </FrontSocketContext.Provider>
    );
};

export default FrontSocketProvider;