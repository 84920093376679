import { t } from "i18next";
import { useState } from "react";
import { FaBars, FaXmark, FaClockRotateLeft, FaComments, FaRegSun } from "react-icons/fa6";
import Chat from "../../chat/Chat";
import History from "../../history/History";
import Settings from "../../settings/Settings";
import "./Menu.scss"
import { useDispatch } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import SoundManager from "../../../../helpers/SoundManager";

const Menu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuItemOpen, setMenuItemOpen] = useState(false);
    const dispatch = useDispatch()

    const openClass = menuOpen && !menuItemOpen? 'open': '';

    return (<div className="right-navigation">
        <div className={`menu-container ${openClass}`} onClick={() => setMenuOpen(false)}>
        </div>
        <ul className={`menu-navigation-items ${openClass}`} >
            <li className="menu-navigation-item">
                <div className="clickable-button" onClick={() => {
                    setMenuItemOpen('history')
                    dispatch(frontActions.toggleHistoryOpened())
                }}>
                    <div className="button-icon">
                        <FaClockRotateLeft color="white" size={30} />
                    </div>
                    <div className="button-label">
                        {t('history')}
                    </div>
                </div>
            </li>
            <li className="menu-navigation-item">
                <div className="clickable-button" onClick={() => {
                    setMenuItemOpen('chat');
                    SoundManager.play('click');
                    dispatch(frontActions.toggleChatOpened())
                }}>
                    <div className="button-icon">
                        <FaComments color="white" size={30} />
                    </div>
                    <div className="button-label">
                        {t('chat')}
                    </div>
                </div>
            </li>
            <li className="menu-navigation-item">
                <div className="clickable-button" onClick={() => {
                    setMenuItemOpen('settings');
                    SoundManager.play('click');
                    dispatch(frontActions.toggleSettingsOpened())
                }}>
                    <div className="button-icon">
                        <FaRegSun color="white" size={30} />
                    </div>
                    <div className="button-label">
                        {t('settings')}
                    </div>
                </div>
            </li>
        </ul>
        <div className="right-bottom-navigation">
            <ul className="navigation-items">
                <li className="navigation-item">
                    <div className="icon-button menu-button" onClick={() => setMenuOpen((prev) => !prev)}>
                        <div className="clickable-button">
                            {!openClass ? <FaBars color="white" />: <FaXmark color="white" />}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div className={`menu-item-window2 ${menuItemOpen ? 'open': '' }`}>
            {/* <div className="close-button" onClick={() => setMenuItemOpen(false)}>
                <FaXmark color="white" size={30}/>
            </div> */}
            {/* <div className="clear-both"></div> */}
            {menuItemOpen === 'history' ? <History closeCallBack={() => { setMenuItemOpen(false)}}/>: ''}
            {menuItemOpen === 'chat' ? <Chat closeCallBack={() => { setMenuItemOpen(false)}} />: ''}
            {menuItemOpen === 'settings' ? <Settings closeCallBack={() => { setMenuItemOpen(false)}} />: ''}
        </div>
    </div>);
}

export default Menu;