import { useDispatch, useSelector } from "react-redux";
import FlashphonerComponent from "../front/FlashphonerComponent/FlashphonerComponent";
import ActionButton from "../front/header/actionButton/ActionButton";
import FpplScreen from "../front/header/fullscreen/Foolscreen";
import { Slider } from "antd";
import { useEffect, useState } from "react";
import IconVolumeOff from "../../assets/icons/IconVolumeOff.svg";
import IconVolumeOn from "../../assets/icons/IconVolumeOn.svg";
import "./GamesView.scss"
import { getStreamURL } from "../../store/slice/frontSlice";

const GamesView = () => {
    
    const frontStore = useSelector((store) => store.front);
    const [volume, setVolume] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStreamURL());
      }, []);
    
    
    return (<div className="public-game-wrapper">
        <div className="header-buttons">
            <ActionButton
                onClick={() => {
                    setVolume(volume ? 0 : 100);
                }}
                popoverContent={<div className="sound-slider-wrapper" onClick={(e) => {e.stopPropagation();}}>
                <Slider
                    range
                    min={0}
                    max={100}
                    value={volume}
                    className="sound-slider"
                    onChange={(val) => {
                        setVolume(val[0])
                    }}
                />
                
            </div>}>
                <img src={!volume ? IconVolumeOff : IconVolumeOn} alt="icon-volume" />
            </ActionButton>
            <FpplScreen />
        </div>
        
        <FlashphonerComponent 
            streamId="belote"
            streamKey={frontStore.streamKey}
            activeResolution="auto"
            volume={{muted: false, value: volume}}
            resolutions={frontStore.resolutions}
        />
    </div>);
}

export default GamesView;