import { Row } from "antd";
import { t } from "i18next";
import './StatusContainer.scss'

const StatusContainer = ({frontStore}) => {
    const game = frontStore?.game;
    const stage = game?.currentConf?.stage;

    return (<Row className={`status-content-${stage} game-status-containers`}>
        {game.currentConf && stage === 'Betting' ? <Row className="status-bets">
            {t('place_bets')} {game?.currentConf?.current}
        </Row>: ''}
        {!['Betting'].includes(stage) ? <Row>{stage}</Row>: ''}
    </Row>);
}

export default StatusContainer;