import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./FlashphonerComponent.scss";
import StreamLoader from './streanLoader/StreamLoader';
import { frontActions, getStreamURL } from '../../../store/slice/frontSlice';
import { isMobileDevice, monitorNetworkConditions, selectStreamBasedOnNetwork } from "../../../helpers/helpers";

function FlashphonerComponent({ streamId, streamKey, activeResolution, resolutions, volume }) {

  const [videoStream, setVideoStream] = useState();
  const [shouldRecreateStream, setShouldRecreateStream] = useState(true);
  const [currentActiveResolution, setCurrentActiveResolution] = useState(activeResolution)
  const loader = useRef();
  const dispatch = useDispatch();
  const resStrng = JSON.stringify(currentActiveResolution);
  
  useEffect(() => {
    if (videoStream) {
      if (volume?.value) {
        videoStream.unmuteRemoteAudio();
      }
      else {
        videoStream.muteRemoteAudio()
      }

      videoStream.setVolume(volume?.value)
    }
  }, [volume?.value, videoStream])


  useEffect(() => {
    setCurrentActiveResolution(activeResolution)
  }, [activeResolution])

  useEffect(() => {
    const networkMonitor = setInterval(() => {
      if (activeResolution == 'auto') {
       // dispatch(frontActions.initialIncreaseVideoQuality())
       switchStream()
      }

    }, 5000)

    return () => clearInterval(networkMonitor);
  }, [currentActiveResolution])

  useEffect(() => {
    if ((!videoStream || shouldRecreateStream) && streamId) {
      if(videoStream) {
        videoStream.stop();
      }
      init_api()
      setShouldRecreateStream(false);
    }
  }, [resStrng, videoStream, shouldRecreateStream, streamId]);

  useEffect(() => {
    const Flashphoner = window.Flashphoner;
    let networkMonitor;
    if (Flashphoner && streamId) {
      setShouldRecreateStream(true); // Trigger recreation of the stream
      console.log("Active activeResolution", activeResolution)
      if (activeResolution === 'auto') {
        networkMonitor = setInterval(() => {
          switchStream()
        }, 5000);
      }
    }

    // Cleanup
    return () => clearInterval(networkMonitor);
  }, [streamId, activeResolution, currentActiveResolution]);

  function init_api() {
    if (!streamKey) return <StreamLoader />;
    const Flashphoner = window.Flashphoner;
    const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
    const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
    let session;
  
    const treamSERVERURL = 
    Flashphoner.init({});
    session = Flashphoner.createSession({
      urlServer: "wss://"+streamKey // Specify the address of your WCS
    }).on(SESSION_STATUS.ESTABLISHED, function (session) {
      const play = document.getElementById('play');
      if (!play) return false;

      let options = {
        name: streamId,
        display: document.getElementById("play"),
        flashShowFullScreenButton: false,
        unmutePlayOnStart: false,
        transport: "auto"
      };

      if (currentActiveResolution && currentActiveResolution.split("x").length > 1) {
        options = {
          ...options,
          playWidth: currentActiveResolution.split("x")[0],
          playHeight: currentActiveResolution.split("x")[1]
        };
      } else if (isMobileDevice()) {
        options = {
          ...options,
          playWidth: 426,
          playHeight: 240
        };
      }

      if (loader.current) loader.current.style.display = 'block';
      const stream = session.createStream(options).on(STREAM_STATUS.PENDING, function (stream) {
        let video = document.getElementById(stream.id());
        video.addEventListener('playing', () => {
          if (loader.current) loader.current.style.display = 'none';
        });
      }).on(STREAM_STATUS.PLAYING, (stream) => {
        setVideoStream(stream)
      }).on(STREAM_STATUS.FAILED, () => {
        console.log("Stream failed to play");
      });
      stream.play();
    });
  }

  function switchStream() {
    const networkConditions = monitorNetworkConditions();

    // Logic to select the appropriate stream based on network conditions
    const selectedResolution = selectStreamBasedOnNetwork(resolutions, networkConditions);
    // Switch to the selected stream
    if (selectedResolution && selectedResolution !== currentActiveResolution) {
      setCurrentActiveResolution(selectedResolution);
      setShouldRecreateStream(true);
    }
  }

  return (
    <>
      <div className="fp-Video">
        <div id="play" className="display"></div>
        <div className="video-loading" ref={loader}>
          <StreamLoader />
        </div>
      </div>
    </>
  );
}

export default FlashphonerComponent;
