import { Col, Row } from "antd";
import "./AmountContainer.scss"
import { useSelector } from "react-redux";

const AmountContainer = ({label, currency = 'AMD', value}) => {
    const frontUserData = useSelector((store) => store.frontUser);

    let amount;
    if (value == 'balance') {
        amount = frontUserData?.user?.balance;
    }
    else if (value == 'totalBets') {
        amount = frontUserData?.totalBets;
    }
    return (
        <Row className="footer-amount-container">
            <Row className="footer-content">
                <Col span={24} className="amount-label">{label}</Col>
                <Col>{currency}</Col>
                <Col>{amount.toFixed(2)}</Col>
            </Row>
        </Row>
    )
}

export default AmountContainer;