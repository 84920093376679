import { Typography, Row, Col } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FrontSocketContext from "../../../../store/FrontSocketContext";
import Card from "../../../partial/card";
import "./RoundHistory.scss"

const RoundHistory = ({roundData, backCallBack}) => {
    const socket = useContext(FrontSocketContext);
    const betsRound = useSelector((store) => store.betsHistory);
    const { t } = useTranslation("translation");

    const playersMap = {
        "player1": "p1",
        "player2": "p2"
    }
    
    useEffect(() => {
        if (roundData && socket) {
            socket.emit("getRoundBets", roundData.roundId)
        }
    }, [roundData, socket]);
    const {Text} = Typography;
    const gameData = JSON.parse(roundData.roundState);
    const {cards} = gameData;
    console.log(123, gameData);

    return (<Row className="round-history-wrapper">
        <div className="top-actions">
            <div className="left-button">
                
                <button onClick={backCallBack}>{t('Return')}</button>
            </div>
        </div>
        <Row className="f-row">
            <Col span={24} className="history-content-wrapper">
                <Row  className="f-row">
                    <Col span={24}>
                        <Text>{t('Round')}</Text> - <Text>{roundData.roundId}</Text>
                    </Col>
                    <Col span={24}>
                        <Text>{t('Date')}</Text> - <Text>{new Date(roundData.createdAt).toLocaleString('en-US', { hour12: false })}</Text>
                    </Col>
                </Row>
                {betsRound.rountBets && <Row className="round-bets-history-table">
                    <Col span={24}>
                        <Row className="round-history-header">
                            <Col span="6"> {t('bet_type')}</Col>
                            <Col span="6"> {t('bet_coeff')}</Col>
                            <Col span="6"> {t('Bet')}</Col>
                            <Col span="6"> {t('Win_lose')}</Col>
                        </Row>
                        <Row className="round-history-content">
                            {betsRound.rountBets.map((bet) => {
                                const diff = bet.totalWinAmount;
                                const condClass = diff > 0 ? 'diff-plus': 'diff-minus';

                                return (<Row className="f-row" key={`cuurent-row-${bet.id}`}>
                                    <Col span="6">{t(bet.betType)} {bet.player ? ' - ' + playersMap[bet.player]: ''}</Col>
                                    <Col span="6">{bet.coefficient}</Col>
                                    <Col span="6">{bet.amount}</Col>
                                    <Col span="6" className={condClass}>{diff}</Col>
                                </Row>)
                            })}
                            <div className="cards-content">
                                <Row className="cards-wrapper">
                                    <Col span={24} className="cards-section">
                                        {
                                            [0,1,2].map((e, i) => {
                                                return (<Card cardName={cards.p1[e].value} key={"p1"+e+i}  i={i} player="p1" />)
                                            })
                                        }
                                    </Col>
                                    <Col span={24} className="cards-section">
                                        {
                                            [3,4,5,6,7,8].map((e, i) => {
                                                return (<Card cardName={cards.p1[e].value} key={"p1"+e+i} i={i} player="p1" />)
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Typography>{t('Trump')}</Typography>
                                    <Card key={"trumpCard"} cardName={cards.trump.value} /> 
                                </Row>
                                <Row className="cards-wrapper">
                                    <Col span={24} className="cards-section">
                                        {
                                            [0,1,2].map((e, i) => {
                                                return (<Card cardName={cards.p2[e].value} key={"p2"+e+i}  i={i} player="p2" />)
                                            })
                                        }
                                    </Col>
                                    <Col span={24} className="cards-section">
                                        {
                                            [3,4,5,6,7,8].map((e, i) => {
                                                return (<Card cardName={cards.p2[e].value} key={"p2"+e+i}  i={i} player="p2" />)
                                            })
                                        }
                                    </Col>
                                </Row> 
                            </div>
                        </Row>
                    </Col>
                </Row>}
            </Col>
        </Row>
    </Row>);
}

export default RoundHistory;
