import { Row, Col } from "antd";
import BetsContainer from "../content/betsContainer/BetsContainer";
import "./GameStatusConatiner.scss";
import { t } from "i18next";

const GameStatusConatiner = ({frontStore, socket}) => {

    const game = frontStore?.game;
    let gameConfig = frontStore.integration.gameConfig;
    const score = game?.resultInfo?.score;
    const p1Score = score?.player1;
    const p2Score = score?.player2;

    let resultClassP1 = "score-lose";;
    let resultClassP2 = "score-win";
    if (p1Score > p2Score) {
        resultClassP2 = "score-lose";;
        resultClassP1 = "score-win";
    }
    
    return (
        <Row className="game-status-container">
            <div className={`title ${(game.currentConf && !['Betting', 'Results'].includes(game.currentConf.stage)) ? 'show' : ''}`}>
                {(game.currentConf && !['Betting', 'Results'].includes(game.currentConf.stage))? t(game.currentConf.stage): ''}
            </div>
            <div className={`wrapper ${(game.currentConf && game.currentConf.stage === 'Betting') ? 'show' : ''}`}>
                <BetsContainer amounts={gameConfig.CURRENCY_BET_AMOUNTS} game={game} socket={socket} className={(game.currentConf && game.currentConf.stage === 'Betting') ? 'show' : ''} />
            </div>
            <div className={`win-wrapper ${(game.currentConf && game.currentConf.stage === 'Results') ? 'show' : ''}`}>
                {game.currentConf && game.currentConf.stage === 'Results' ? <Row>
                    <Col span={24}>
                    <span className="results">{t(game.currentConf.stage)}</span>
                    <p>{frontStore.totalWinAmount ? <span className="win-amount-message">You Winn: <span className="win-amoint">{frontStore.totalWinAmount}</span> </span>: ''}</p>
                    <Row justify="space-between">
                        <Col span={12} className="results-score-wrapper">
                            P2 <span className={`results-score ${resultClassP2}`}>{p2Score}</span>
                        </Col>
                        <Col span={12} className="results-score-wrapper">
                            P1 <span className={`results-score ${resultClassP1}`}>{p1Score}</span>
                        </Col>
                        
                    </Row>
                    </Col>
                </Row>: ''}
            </div>
        </Row>
    );
}

export default GameStatusConatiner;