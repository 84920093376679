import ActionButton from "../actionButton/ActionButton";
import { useDispatch } from "react-redux";
import { frontActions } from "../../../../store/slice/frontSlice";
import { t } from "i18next";
import IconHistory from "../../../../assets/icons/IconHistory.svg";

const History = () => {
    const dispatch = useDispatch();

    return (<ActionButton onClick={() => 
        {
            dispatch(frontActions.toggleHistoryOpened())
            dispatch(frontActions.updateChatOpened(false))
        }
        } popoverContent={t('history')}>
        <img src={IconHistory} alt="icon-history" />
    </ActionButton>)
}

export default History;