import axios, { AxiosInstance } from "axios";
import authData from "../services/authData";
import playerAuthData from "../services/playerAuthData"

export const getToken = () => {
  const token = authData?.get() ?? "";
  return "Bearer " + token;
};


export const getPlayerToken = () => {
  const token = playerAuthData?.get() || "";
  return `Barer ${token}`;
}