export const isMobileDevice = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const monitorNetworkConditions = () => {
    if ('connection' in navigator) {
      const connection = navigator.connection;
      return {
        type: connection.type, // Network type (e.g., "wifi", "cellular")
        effectiveType: connection.effectiveType, // Effective network type (e.g., "4g")
        downlink: connection.downlink, // Downlink speed in Megabits per second
        rtt: connection.rtt // Estimated round-trip time in milliseconds
      };
    } else {
      // Fallback if the connection API is not supported
      return {
        type: 'unknown',
        effectiveType: 'unknown',
        downlink: 'unknown',
        rtt: 'unknown'
      };
    }
}

export const selectStreamBasedOnNetwork = (streams, networkConditions) => {
    const { effectiveType, downlink, rtt } = networkConditions;
    console.log("downlinkdownlinkdownlink ", downlink)
    const streamQualityThresholds = {
        lowQuality: 1,    // Mbps
        mediumQuality: 1.3, // Mbps
        highQuality: 10   // Mbps
      };
    
      // Example logic: Select stream based on downlink speed
      if (downlink >= streamQualityThresholds.highQuality) {
        // If downlink speed exceeds the threshold for high-quality stream, select high-quality stream
        return streams['2160p'];
      } else if (downlink >= streamQualityThresholds.mediumQuality) {
        // If downlink speed exceeds the threshold for medium-quality stream but not high-quality, select medium-quality stream
        return streams['720p'];
      } else {
        // Default: Select low-quality stream
        return streams['240p'];
      }
}  