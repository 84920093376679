import authData from "../../../../services/authData";
import SocketContext from "../../../../store/SocketContext";
import { initialState, tableActions } from "../../../../store/slice/tableSlice";
import { useContext, useEffect, useState } from "react";
import { Button, message, Switch, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { chatActions } from "../../../../store/slice/chatSlice";
import LanguageContext from "../../../../store/LanguageContext";
import { FlagIcon } from "react-flag-kit";


const GameTableHeaderButtons = () => {
    const socket = useContext(SocketContext)
    const game = useSelector(state => state.game);
    const TableStore = useSelector((state) => state.table)
    const i18n = useContext(LanguageContext);
    const dispatch = useDispatch();
    const language = TableStore.language;
    const [manually, setManually] = useState(false);

    const tableLogout = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure?")) {
            authData.clear();
            socket.emit("checkIsLoggedin");
            window.location.reload()
        }
    };

    const changeMe = (e) => {
        e.preventDefault();
        if (e.nativeEvent.pointerType === 'mouse') {
            socket.emit("changeMe");
            chatActionMessage('Dealer Wants to Change');
        }
    };

    const mistake = (e) => {
        e.preventDefault();
        if (e.nativeEvent.pointerType === 'mouse') {
            socket.emit("dealerMistake");
            chatActionMessage('Dealer made Mistake');
        }
    };

    const talk = (e) => {
        e.preventDefault();
        if (e.nativeEvent.pointerType === 'mouse') {
            chatActionMessage('Dealer Wants to Talk');
            socket.emit("talk")
        }
    }

    const pause = (e) => {
        e.preventDefault();
        if (e.nativeEvent.pointerType === 'mouse') {
            socket.emit('togglePause');
        }
    };

    const handleClick = () => {
        setManually(true);
        dispatch(tableActions.swithcThemeMode())
    };

    const chatActionMessage = (message) => {
        socket.emit("dealerMessage", {message})
    }

    useEffect(() => {
        if (manually) {
          // Emit updated data only if language and themeMode are available
          updateDealerSettings();
        }
      }, [TableStore.language, TableStore.themeMode, TableStore.manually]);

    const updateDealerSettings = () => {

        socket.emit("changeDealerSetting", {
            language,
            themeMode: TableStore.themeMode
        })
    }

    useEffect(() => {
        if (!socket) {return;}
        socket.on("newMessage", (response) => {
            dispatch(chatActions.addMessage(response));
        })
        socket.on("clearMessages", () => {
            dispatch(chatActions.clearMessages());
        });
        socket.on("sendChatMessage", (message) => {
            dispatch(chatActions.addPlayerToDealerMessage(message))
        })
        socket.on("setDeletedMessageId", (messageId) => {
            dispatch(chatActions.deletePlayerToDealerMessage(messageId))
        })
        
    }, [socket])

    const changeLanguage = (iso) => {
        i18n.changeLanguage(iso);
        dispatch(tableActions.setLanguage(iso));
        setManually(true)
    }

    const { Option } = Select;
    useEffect(() => {

        if (TableStore?.table?.dealer?.meta?.length) {
            const metaData = JSON.parse(TableStore?.table?.dealer?.meta);

            changeLanguage(metaData.language);
            dispatch(tableActions.setThemeMode(metaData.themeMode));
        }
        else {
            changeLanguage(initialState.language);
            dispatch(tableActions.setThemeMode(initialState.themeMode));
        }

    }, [TableStore?.table?.dealer?.id]);

    return (<>
        <Button type="link" onClick={tableLogout}>Logout</Button>
        <Switch onClick={handleClick} checked={!TableStore.themeMode} className="theme-button" checkedChildren="Dark" unCheckedChildren="Light" />
        <Select
            placeholder="Select a country"
            optionFilterProp="children"
            value={language}
            onChange={changeLanguage}
            defaultValue={language} // Set the default value here
        >
            <Option value="en-US">
                <FlagIcon code="US" />
            </Option>
            <Option value="ru">
                <FlagIcon code="RS" />
            </Option>
            <Option value="hy-AM">
                <FlagIcon code="AM" />
            </Option>
        </Select>
        <Button type="primary" className="primary-color" onClick={changeMe}>Change me</Button>
        <Button type="primary" className="secondary-color" onClick={pause}>{game.paused ? 'Paused': 'Pause'}</Button>
        <Button type="primary" className="talk-color" onClick={talk}>Talk</Button>
        <Button type="primary" className="mistake-color" onClick={mistake}>Mistake</Button>
    </>)
}

export default GameTableHeaderButtons;