import { type } from "@testing-library/user-event/dist/type";
import { t } from "i18next";
import SoundManager from "../../../../helpers/SoundManager";
import BetCircle from "../betCircle/BetCircle";
import BottomBlock from "../bottomBlock/BottomBlock";
import TableChip from "../tableChip/TableChip";
import "./BetBox.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper } from '@fortawesome/free-solid-svg-icons';

const BetBox = ({
    boxIndex,
    coefficient,
    bet,
    totalPlayersBets,
    totalUserAcceptedBet,
    bettingOpen,
    mustLogin,
    cards,
    betLimits,
    frontStore,
    roomConnection,
    selectedChip,
    balance,
    totalGameBets,
    room
}) => {
    const {game} = frontStore;
    const {bets} = frontStore;
    const hand = game?.currentRound?.hand

    const marginLeft =  {
        5: 1,
        4: 17,
        3: 35,
        2: 20,
        1: 10,
        0: 0,
    }
    const marginTop = {
        5: 5,
        4: 8,
        3: 12,
        2: 0,
        1: 0,
        0: 0,
    }
    const boxLabels = {
        1: "P1",
        2: "Draw",
        3: "P2",
    }
    const players = {
        0: "player1",
        1: "",
        2: "player2"
    }
    const isBetting = game?.currentConf?.stage === 'Betting';
    let bottomVal = coefficient ?? '';
    if (bottomVal === 0) {
        bottomVal = t('fold');
    }
    const betAmount = bets.reduce((a, current) => {
        const player = players[boxIndex-1];
        const type = player ? "win": "draw";

        if (current.player === player && current.type === type) {
            a += current.amount;
        }

        return a;
    }, 0)
    const player = players[boxIndex-1];
    const type = player ? "win": "draw";
    return (
        <div className={`belote-bet-box-container test-${type}-${player}`}>
            <div className="box-relative-container" onClick={() => {
                    if (isBetting) {
                        
                        SoundManager.play("placeChip")
                        bet({
                            player,
                            type,
                        })
                    }
                }} >
            
                <TableChip amount={betAmount} />
                <BetCircle lost={coefficient === 0} win={coefficient === 1}>
                    {boxLabels[boxIndex]}
                </BetCircle>
                <BottomBlock value={bottomVal} additional={{player: player, type: type}}/>
                
            </div>
            {(boxIndex == 1 && hand == 1) && <FontAwesomeIcon className="player-hand" icon={faHandPaper} size="3x" color="#e4a21f" title={t('first_hand')} />}
            {(boxIndex == 3 && hand == 2) && <FontAwesomeIcon className="player-hand left" icon={faHandPaper} size="3x" color="#e4a21f" title={t('second_hand')} />}
        </div>
    )
}

export default BetBox;