// SoundManager.js
class SoundManager {
    constructor() {
      this.audio = new Audio();
      window.addEventListener("changeGameSound", (e,args) => {
        const {value} = e.detail;
        this.audio.volume = (value/100)
      })
    }
  
    play(name) {
      this.audio.src = `sounds/${name}.mp3`;
      this.audio.play();
    }
  }
  
  export default new SoundManager();