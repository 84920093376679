import "./Timer.scss";

const Timer = ({secondsLeft, duration}) => {
    const changeFontSize = (value) => {
        if (value) {
          if (value.toString().length >= 5) {
            return '2.1vmin';
          }
          if (value.toString().length >= 4) {
            return '2.2vmin';
          }
          if (value.toString().length >= 3) {
            return '2.7vmin';
          }
        }
        return '3vmin';
    }
    const percentLeft = duration > 0 ? 100 - (secondsLeft * 100) / duration : 0;
        return (
    <div className="circle-timer-container">
      <span className="circle-timer-counter" style={{"fontSize": "2.5vmin"}}>{secondsLeft}</span>
      <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="20"
            cy="20"
            r="18"
            strokeWidth="2"
            stroke="#f3ca3e"
            fill="#1e1e44"
            strokeDasharray="130"
            strokeDashoffset="130"
            style={{strokeDashoffset: 0 - Math.floor(percentLeft * 130 / 100)}}
          ></circle>
      </svg>
    </div>
    );
}

export default Timer;