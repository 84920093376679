import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import authData from "../../services/playerAuthData";
import FrontSocketProvider from "../../store/FrontSocketProvider";
import FrontContent from "./FrontContent";

const Belote = () => {
    const frontStore = useSelector((store) => store.front);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    
    if (token) {
      authData.set(token, true)
    }

    return (<FrontSocketProvider>
        <FrontContent />
    </FrontSocketProvider>)
}

export default Belote;