const LimtTabelRow = ({coefficientName, player}) => {

    return (
        <tr className="history-item" onClick={() => {}}>
            <td className="text-left">{coefficientName}{player ? ' - '+player: ''}</td>
            <td className="text-left">100</td>
            <td className="text-right">500K</td>
        </tr>
    )
}

export default LimtTabelRow;