import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ChatBox from "./../../partial/chatBox";
import "./GameTableFooter.scss";

const GameTableFooter = () => {
  const chat = useSelector((store) => store.chat);
  let messages = chat?.messages;

  return (
    <Row style={{ width: "100%" }} className="footer-messages-wrapper">
      <Col span={11} className="chat-wrapper">
          <ChatBox messages={messages} />
        </Col>
        <Col span={11} className="chat-wrapper">
          <ChatBox messages={chat.playerToDealerMessages} />
        </Col>
    </Row>
  );
};

export default GameTableFooter;
